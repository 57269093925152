import { Filters } from 'react-table';
import { IFilter } from '@services';

export const getFilterParameters = <T extends object>(filters: Filters<T> | undefined): Record<string, string> => {
  return typeof filters === 'undefined'
    ? {}
    : filters
      .reduce((result: Record<string, string>, item: IFilter) => {
        if (!!item.value) {
          result[item.id] = item.value;
        }
        return result;
      },
      {}
      );
};
