import { IProperty } from '../../interfaces';

const closeOnInputClickProperty: IProperty = {
  name: 'closeOnInputClick',
  category: 'calendar',
  type: 'switch',
  default: false,
  visible: true,
  visibleIndex: 14
};

export default closeOnInputClickProperty;
