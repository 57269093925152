import { IFileUploadQuestion } from '../fileUpload.typings';
import { getSupportedFileTypeArray } from './getSupportedFileTypeArray';

export const validateDragEnter = (
  event: DragEvent,
  question: IFileUploadQuestion
): boolean => {
  const { dataTransfer } = event;
  if (!dataTransfer) {
    return true;
  }
  const maxFileCount = question.maxFileCount
    ? +question.maxFileCount
    : 0;
  const dataTransferItems = dataTransfer.items;
  const itemCount = dataTransferItems.length;
  if (!itemCount) {
    return true;
  }
  if (maxFileCount > itemCount) {
    return false;
  }
  const supportedFileTypeArray: string[] = getSupportedFileTypeArray(question.acceptedTypes);
  if (!supportedFileTypeArray.length) {
    return true;
  }
  return Array.from(dataTransferItems)
    .map((item: DataTransferItem) => {
      return supportedFileTypeArray.includes(item.type);
    })
    .every((valid: boolean) => valid);
};
