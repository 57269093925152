import ButtonWidget from './button.widget';
import { CustomWidgetCollection } from '@shared/surveyJs/reexport';

const Button = (): void => {
  const widget = new ButtonWidget();
  CustomWidgetCollection.Instance.addCustomWidget(
    widget,
    widget.activatedBy
  );
};

export default Button;
