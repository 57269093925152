import { ICompanyDataService } from '@services';
import { companyDataApiService } from '@services/api';

export const companyDataService: ICompanyDataService = {
  getProcesses: companyDataApiService.getProcesses.bind(companyDataApiService),
  getTom: companyDataApiService.getTom.bind(companyDataApiService),
  getCount: companyDataApiService.getCount.bind(companyDataApiService),
  getSelectableFunctionalArea: companyDataApiService.getSelectableFunctionalArea.bind(companyDataApiService),
  deleteCustomProcess: companyDataApiService.deleteCustomProcess.bind(companyDataApiService),
  companyAction: companyDataApiService.companyAction.bind(companyDataApiService)
};
