import { IProperty } from '../../interfaces';
import { QuestionCheckboxModel } from '@shared/surveyJs/reexport';

const otherPlaceHolderProperty: IProperty = {
  name: 'otherPlaceHolder',
  category: 'choices',
  type: 'string',
  default: '',
  isLocalizable: true,
  dependsOn: 'hasOther',
  visibleIf: (question: QuestionCheckboxModel) => question.hasOther,
  nextToProperty: 'otherText'
};

export default otherPlaceHolderProperty;
