import { IProperty } from '../../interfaces';

const allowClearProperty: IProperty = {
  name: 'allowClear',
  category: 'general',
  type: 'switch',
  default: true,
  visible: true,
  visibleIndex: 50
};

export default allowClearProperty;
