import { IProperty } from '../../interfaces';
import { ICalendarQuestion } from '../calendar.widget';

const allowSingleDateProperty: IProperty = {
  name: 'allowSingleDate',
  dependsOn: [ 'singleDate', 'singleCalendar' ],
  category: 'calendar',
  type: 'switch',
  default: false,
  visible: true,
  visibleIf: (question: ICalendarQuestion) => {
    return !question.singleDate && !question.singleCalendar;
  },
  visibleIndex: 1,
  categoryIndex: 1
};

export default allowSingleDateProperty;
