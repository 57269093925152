import { Route } from 'router5';
import { DoneFn } from 'router5/dist/types/base';
import { forkJoin, Observable, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError } from 'rxjs/operators';
import { RouteDictionary } from '@interfaces';
import {
  getDynamicRoutePermission,
  getRouteListTypeByRouteName,
  ResolverData,
  ResolveFactoryDictionary,
  routerDependencies
} from '@router';
import { IRouteState, RouteStateData } from '../interfaces';
import { permissionService } from '@services';
import { isEmpty } from '@utilities';
import { RouteListType } from '../configuration';

export const errorParameterName = 'error';

const getResolveFactoryDictionary = (
  resolverData: RouteDictionary<ResolveFactoryDictionary>
): ResolveFactoryDictionary =>
  (Object.keys(resolverData) as RouteListType[]).reduce((result: ResolveFactoryDictionary, route: RouteListType) => {
    if (!isEmpty(result)) {
      return result;
    }
    return permissionService.isPageAvailable(route)
      ? resolverData[route]!
      : result;
  }, {});

export const resolveMiddleware = () => (
  toState: Route & { params: Record<string, string> },
  _fromState: Route,
  done: DoneFn
): void => {
  const { isLoggedIn, error: routeError } = toState as IRouteState;
  if (routeError) {
    return done();
  }
  const dependency = routerDependencies[toState.name];
  const route = getRouteListTypeByRouteName(toState.name);
  let permission = permissionService.getComponentsPermission(route);

  if (typeof dependency === 'undefined') {
    (toState as IRouteState).data = {
      ...(toState as IRouteState).data,
      permission,
      isLoggedIn,
      error: null
    };
    return done();
  }
  if (typeof dependency.resolve === 'undefined' || !Object.keys(dependency.resolve).length) {
    (toState as IRouteState).data = {
      ...(toState as IRouteState).data,
      permission,
      isLoggedIn,
      error: null
    };
    return done();
  } else {
    const { dynamic } = dependency;
    const resolveFactoryDictionary: ResolveFactoryDictionary = dynamic
      ? getResolveFactoryDictionary(dependency.resolve as RouteDictionary<ResolveFactoryDictionary>)
      : (dependency.resolve as ResolveFactoryDictionary);
    if (dynamic) {
      permission = getDynamicRoutePermission(Object.keys(dependency.resolve) as RouteListType[]);
    }
    const data: ResolverData = { parameter: toState.params, permission, isLoggedIn };
    const resolveDictionary: Record<string, Observable<any>> = Object.keys(resolveFactoryDictionary).reduce(
      (result: Record<string, Observable<any>>, key: string) => ({
        ...result,
        [key]: resolveFactoryDictionary[key](data)
      }),
      {}
    );
    forkJoin(resolveDictionary)
      .pipe(
        catchError((error: AjaxError) => {
          if (error.status === 401) {
            (toState as IRouteState).error = 401;
            return of({});
          } else {
            (toState as IRouteState).error = error.status === 403 ? 403 : error.status >= 500 ? 500 : error.status;
            return of({});
          }
        })
      )
      .subscribe((result: Record<string, any>) => {
        (toState as IRouteState).data = {
          ...(toState as IRouteState).data,
          ...(result as RouteStateData),
          permission,
          isLoggedIn,
          error: null
        };
        return done();
      });
  }
};
