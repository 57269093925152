import { redirect, redirectParameterName } from '@router';

interface IDefaultRedirectOptions {
  useRedirect?: boolean;
  parameters?: object;
}

interface IDefaultRedirectParameters {
  useRedirect: boolean;
  parameters: object;
}

const defaultParameters: IDefaultRedirectParameters = {
  useRedirect: true,
  parameters: {}
};

const defaultRedirect = (options: IDefaultRedirectOptions = {}) => {
  const { useRedirect, parameters } = { ...defaultParameters, ...options };
  useRedirect
    ? redirect('default', { ...parameters, [redirectParameterName]: false })
    : redirect('default');
};

export default defaultRedirect;
