import { DataFormat } from 'select2';
import { SystemsEntity } from '@services';

const apiListMapper = (
  dataList: SystemsEntity[] = []
): DataFormat[] => dataList
  .map(
    (data: SystemsEntity) => ({
      id: data.id,
      text: data.title ?? ''
    })
  );

export default apiListMapper;
