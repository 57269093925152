import FileUploadWidget from './fileUpload.widget';
import { CustomWidgetCollection } from '@shared/surveyJs/reexport';

const Fileupload = (): void => {
  const widget = new FileUploadWidget();
  CustomWidgetCollection.Instance.addCustomWidget(
    widget,
    widget.activatedBy
  );
};

export default Fileupload;
