import { IProperty } from '../../interfaces';

const startWeekFromSundayProperty: IProperty = {
  name: 'startWeekFromSunday',
  category: 'calendar',
  type: 'switch',
  default: false,
  visible: true,
  visibleIndex: 100
};

export default startWeekFromSundayProperty;
