import { Serializer } from '@shared/surveyJs/reexport';

const renameSurveyClass = (oldName: string, newName: string) => {
  if (Serializer.classes[oldName]) {
    Serializer.classes[newName] = Serializer.classes[oldName];
    delete Serializer.classes[oldName];
  }
  if (Serializer.childrenClasses[oldName]) {
    Serializer.childrenClasses[newName] = Serializer.childrenClasses[oldName];
    delete Serializer.childrenClasses[oldName];
  }
  if (Serializer.alternativeNames[oldName]) {
    Serializer.alternativeNames[newName] = Serializer.alternativeNames[oldName];
    delete Serializer.alternativeNames[oldName];
  }
};

export default renameSurveyClass;
