import { IProperty } from '../../interfaces';

const hasSelectAllProperty: IProperty = {
  name: 'hasSelectAll',
  category: 'choices',
  type: 'switch',
  default: false,
  visible: true,
  visibleIndex: 20
};

export default hasSelectAllProperty;
