import React, { FC, ReactElement } from 'react';
import { IAreaChartData, IAreaChartSettings } from '../AreaChart';

import styles from '../AreaChart.styl';
import Label from '@pages/Dashboard/components/AreaChart/components/Label';
import { useTranslation } from 'react-i18next';
import AxisLabel from '@pages/Dashboard/components/AreaChart/components/AxisLabel';

interface IOwnProps {
  settings: IAreaChartSettings;
}

type Props = IOwnProps;

const DataGroup: FC<Props> = ({ settings }): ReactElement => {
  const { t } = useTranslation('dashboard');

  const totalSum = settings.parameters.areaDictionary.total.reduce(
    (result: number, item: number) => result + item,
    0
  );

  let centerLabel = null;
  const centerLabelCoordinates = {
    x: 0,
    y: 0
  };
  if (totalSum) {
    const valueSum = settings.parameters.areaDictionary.value.reduce(
      (result: number, item: number) => result + item,
      0
    );
    const totalLabelText = `${ valueSum } / ${ totalSum }`;
    centerLabel = (
      <Label
        width={85}
        height={22}
        text={totalLabelText}
        className={styles.totalLabel}
        coordinates={centerLabelCoordinates}
      />
    );
  } else {
    centerLabel = (
      <Label
        width={320}
        height={50}
        text={t('areaWidget.empty')}
        className={styles.emptyLabel}
        coordinates={centerLabelCoordinates}
      />
    );
  }

  const labelElementList: ReactElement[] = settings.parameters.data
    .map(
      (item: IAreaChartData, index: number) => (
        <AxisLabel
          key={item.label}
          index={index}
          item={item}
          settings={settings}
        />
      )
    );

  return (
    <g className={styles.dataGroup}>
      {labelElementList.map((label: ReactElement) => label)}
      {centerLabel}
    </g>
  );
};

export default DataGroup;
