import AdapterWidget from './adapterWidget';
import { CustomWidgetCollection } from '@shared/surveyJs/reexport';

const Object = (): void => {
  const widget = new AdapterWidget();
  CustomWidgetCollection.Instance.addCustomWidget(
    widget,
    widget.activatedBy
  );
};

export default Object;
