import { IProperty } from '../../interfaces';

const dictionaryProperty: IProperty = {
  name: 'dictionary',
  category: 'choices',
  type: 'dictionaries',
  default: '',
  visible: true,
  visibleIndex: 0
};

export default dictionaryProperty;
