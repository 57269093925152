import LinkWidget from './link.widget';
import { CustomWidgetCollection } from '@shared/surveyJs/reexport';

const Link = (): void => {
  const widget = new LinkWidget();
  CustomWidgetCollection.Instance.addCustomWidget(
    widget,
    widget.activatedBy
  );
};

export default Link;
