import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { take } from 'rxjs/operators';
import { PageType, IRouteState, ComponentTypeDictionary } from '../interfaces';
import { PagePermissionData, permissionService } from '@services';
import { RouteListType } from '../configuration';
import { getRouteListTypeByRouteName, routerDependencies } from '@router';

const isUndefined = (value: any): boolean => typeof value === 'undefined';

const check = (toState: IRouteState): Promise<boolean> => {
  const routeName = getRouteListTypeByRouteName(toState.name);
  return new Promise((resolve: (value: boolean) => void) => {
    permissionService.pagePermissionSubject$
      .pipe(take(1))
      .subscribe((pagePermissionData: null | PagePermissionData) => {
        if (pagePermissionData === null) {
          return resolve(false);
        }
        const { dynamic, component } = routerDependencies[toState.name];
        if (dynamic) {
          const routeList = Object.keys(component as ComponentTypeDictionary) as RouteListType[];
          const pagePermissionList: Array<undefined | boolean> = routeList.map(
            (routeListType: RouteListType) => pagePermissionData[routeListType]
          );
          if (pagePermissionList.every((pagePermission: undefined | boolean) => isUndefined(pagePermission))) {
            toState.error = 404;
          } else if (pagePermissionList.every((pagePermission: undefined | boolean) => !pagePermission)) {
            toState.error = 403;
          }
        } else {
          const pagePermission: undefined | boolean = pagePermissionData[routeName as RouteListType];
          if (isUndefined(pagePermission)) {
            toState.error = 404;
          } else if (!pagePermission) {
            toState.error = 403;
          }
        }
        return resolve(true);
      });
  });
};

export const permissionGuard: ActivationFnFactory = (): ActivationFn => (toState: State): Promise<boolean> => {
  const { error, pageType } = toState as IRouteState;
  if (error || pageType !== PageType.private) {
    return Promise.resolve(true);
  }
  return check(toState as IRouteState);
};
