import { IProperty } from '../../interfaces';
import { QuestionCheckboxModel } from '@shared/surveyJs/reexport';

const otherErrorTextProperty: IProperty = {
  name: 'otherErrorText',
  category: 'validation',
  type: 'string',
  default: '',
  isLocalizable: true,
  dependsOn: 'hasOther',
  visibleIf: (question: QuestionCheckboxModel) => question.hasOther
};

export default otherErrorTextProperty;
