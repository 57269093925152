import {
  redirectToLobby,
  RedirectToLobbyOptions
} from '@router';
import { productService } from '@services';

export type UseLobbyRedirectReturn = {
  redirect: (options?: RedirectToLobbyOptions) => void;
};

export const productParameterName = 'product';

export const useLobbyRedirect = (): UseLobbyRedirectReturn => {
  const product = productService.getCurrentProduct();
  const defaultParameters = {
    [productParameterName]: product
  };

  const redirect = (options: RedirectToLobbyOptions = {}): void => {
    const { route, parameters } = options;
    redirectToLobby({
      route,
      parameters: {
        ...defaultParameters,
        ...parameters
      }
    });
  };

  return { redirect };
};
