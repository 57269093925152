import { IProperty } from '../../interfaces';

const minRelativeDateProperty: IProperty = {
  name: 'minRelativeDate',
  category: 'calendar',
  type: 'string',
  default: '',
  isLocalizable: false,
  visible: true,
  visibleIndex: 7
};

export default minRelativeDateProperty;
