import { IFileUploadQuestion } from '../fileUpload.typings';
import { createElement } from '../../common';
import { getConvertedSize } from '../utilities';

export const createPanelHtml = (
  question: IFileUploadQuestion,
  classNameDictionary: Record<string, string>,
  getLocalization: (question: IFileUploadQuestion, key: string) => string
): HTMLElement => {
  const buttonElement = createElement<HTMLButtonElement>({
    tagName: 'button',
    classNames: classNameDictionary.clearButton,
    attributes: {
      type: 'button',
      disabled: true
    },
    content: getLocalization(question, 'clearUnsupportedFiles')
  });

  const sizeValueElement = createElement({
    tagName: 'span',
    classNames: [ classNameDictionary.statisticsValue, classNameDictionary.sizeValue ],
    content: getConvertedSize(0, question, getLocalization)
  });
  const sizeLimitValueElement = createElement({
    tagName: 'span',
    classNames: [ classNameDictionary.statisticsLimitValue, classNameDictionary.sizeLimitValue ],
    content: getConvertedSize(0, question, getLocalization)
  });
  const sizeLimitElement = createElement({
    tagName: 'span',
    classNames: [
      classNameDictionary.statisticsLimit,
      classNameDictionary.sizeLimit,
      ...(question.maxSize ? [ classNameDictionary.hidden ] : [])
    ],
    content: [ '/', sizeLimitValueElement ]
  });
  const sizeElement = createElement({
    tagName: 'p',
    classNames: classNameDictionary.statisticsItem,
    content: [
      getLocalization(question, 'sizeText'),
      sizeValueElement,
      sizeLimitElement
    ]
  });

  const counterValueElement = createElement({
    tagName: 'span',
    classNames: [ classNameDictionary.statisticsValue, classNameDictionary.counterValue ],
    content: [ '0' ]
  });
  const counterLimitValueElement = createElement({
    tagName: 'span',
    classNames: [
      classNameDictionary.statisticsLimitValue,
      classNameDictionary.counterLimitValue
    ],
    content: '0'
  });
  const counterLimitElement = createElement({
    tagName: 'span',
    classNames: [
      classNameDictionary.statisticsLimit,
      classNameDictionary.counterLimit,
      ...(!question.minFileCount && !question.maxFileCount ? [ classNameDictionary.hidden ] : [])
    ],
    content: [ '/', counterLimitValueElement ]
  });
  const counterElement = createElement({
    tagName: 'p',
    classNames: classNameDictionary.statisticsItem,
    content: [
      getLocalization(question, 'counterText'),
      counterValueElement,
      counterLimitElement
    ]
  });

  const statisticsElement = createElement({
    classNames: classNameDictionary.statistics,
    content: [ sizeElement, counterElement ]
  });

  return createElement({
    classNames: classNameDictionary.panel,
    content: [
      buttonElement,
      statisticsElement
    ]
  });
};
