import { IProperty } from '../../interfaces';

const maxDateProperty: IProperty = {
  name: 'maxDate',
  category: 'calendar',
  type: 'date',
  isLocalizable: true,
  visible: true,
  visibleIndex: 6
};

export default maxDateProperty;
