import { IProperty } from '../../interfaces';

const placeholderProperty: IProperty = {
  name: 'placeholder',
  category: 'general',
  type: 'string',
  default: '',
  isLocalizable: true,
  visible: true,
  visibleIndex: 4
};

export default placeholderProperty;
