import { Api } from '@services/api';
import { Observable } from 'rxjs';

const apiUrl = '/api/health';

export class HealthApiService extends Api {
  public healthCheck(): Observable<number> {
    return this.get<number>({
      suppressDefaultErrorHandler: true,
      returnType: 'status'
    });
  }
}

export const healthApiService = new HealthApiService(apiUrl);
