import React, { FC, ReactElement } from 'react';
import { getBaseAngle } from '../utilities';
import { IAreaChartSettings } from '../AreaChart';
import Axis from './Axis';

import styles from '../AreaChart.styl';

interface IOwnProps {
  settings: IAreaChartSettings;
}

type Props = IOwnProps;

const AxisGroup: FC<Props> = ({ settings }): ReactElement => {
  const baseAngle = getBaseAngle(settings.parameters.axisCount);
  const axisElementList: ReactElement[] = Array
    .from(
      Array(settings.parameters.axisCount),
      (_, index: number) => (
        <Axis
          key={index}
          className={styles.axis}
          angle={baseAngle * index}
          length={settings.parameters.axisLength}
        />
      )
    );
  const axisValueElementList: ReactElement[] = settings.axis.showAxisValue
    ? Array
      .from(
        Array(settings.parameters.axisCount),
        (_, index: number) => {
          const value = settings.parameters.areaDictionary.value[index];
          const maxValue = settings.parameters.maxValueDictionary[index];
          const axisLength = settings.parameters.axisLength;
          const length = settings.parameters.maxValueDictionary[index]
            ? value / maxValue * axisLength
            : 0;
          return (
            <Axis
              key={index}
              className={`${ styles.axis } ${ styles.axisValue }`}
              angle={baseAngle * index}
              length={length}
            />
          );
        }
      )
    : [];
  const axisEmptyElementList: ReactElement[] = settings.axis.showAxisValue
    ? Array
      .from(
        Array(settings.parameters.axisCount),
        (_, index: number) => {
          const axisLength = settings.parameters.axisLength;
          const length = settings.parameters.maxValueDictionary[index]
            ? axisLength
            : 0;
          return (
            <Axis
              key={index}
              className={`${ styles.axis } ${ styles.axisEmpty }`}
              angle={baseAngle * index}
              length={length}
            />
          );
        }
      )
    : [];
  return (
    <g className={styles.axisGroup}>
      {axisElementList.map((axis: ReactElement) => axis)}
      {axisEmptyElementList.map((axisEmpty: ReactElement) => axisEmpty)}
      {axisValueElementList.map((axisValue: ReactElement) => axisValue)}
    </g>
  );
};

export default AxisGroup;
