import React, { FC, ReactElement } from 'react';
import { IAreaChartSettings, ICoordinates } from '../index';
import { getCoordinates, getPoint } from '../utilities';

import styles from '../AreaChart.styl';

interface IOwnProps {
  axisCount: number;
  distance: number;
  settings: IAreaChartSettings;
}

type Props = IOwnProps;

const Grid: FC<Props> = ({ axisCount, distance, settings }): ReactElement => {
  const points: string = Array
    .from(
      Array(axisCount),
      (_, index: number) => getCoordinates(settings.parameters.baseAngle * index, distance)
    )
    .map((coordinates: ICoordinates) => `${ getPoint(coordinates.x) },${ getPoint(coordinates.y) }`)
    .join(' ');

  return (
    <polygon
      className={styles.grid}
      points={points}
    />
  );
};

export default Grid;
