import { createElement } from '../../common';

export const createDropZoneInfoItemHtml = (
  classNameDictionary: Record<string, string>,
  text: string,
  value: number | string
) => createElement({
  tagName: 'p',
  classNames: classNameDictionary.dropZoneInfoText,
  content: [
    text,
    createElement({
      tagName: 'span',
      classNames: classNameDictionary.dropZoneInfoValue,
      content: value
    })
  ]
});
