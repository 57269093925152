export const acceptedTypes = [
  'pdf',
  'png',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx'
];
export const acceptedTypesString = acceptedTypes.join(', ');
