import { Serializer } from '@shared/surveyJs/reexport';

Serializer.addProperty('survey', {
  name: 'redirectDelay',
  type: 'number',
  default: 0
});

Serializer.addProperty('question', {
  name: 'label',
  type: 'string',
  isLocalizable: true
});

Serializer.addProperty('question', {
  name: 'cachedValue',
  visible: false
});

Serializer.addProperty('question', {
  name: 'autocompleteAttr',
  type: 'string'
});

Serializer.addProperty('question', {
  name: 'nameAttr',
  type: 'string'
});

Serializer.addProperty('question', {
  name: 'testAttr',
  type: 'string'
});

const valueMaxLength = {
  name: 'valueMaxLength',
  category: 'general',
  type: 'number',
  default: 255,
  visible: true,
  visibleIndex: -1,
  index: 0
};

Serializer.addProperty('text', valueMaxLength);
Serializer.addProperty('url', valueMaxLength);
Serializer.removeProperty('text', 'maxLength');
Serializer.removeProperty('url', 'maxLength');

Serializer.removeProperty('text', 'size');
