import { IProperty } from '../../interfaces';

const restoreButtonProperty: IProperty = {
  name: 'restoreButton',
  category: 'calendar',
  type: 'switch',
  default: false,
  visible: true,
  visibleIndex: 12
};

export default restoreButtonProperty;
