import { clone } from '@utilities';
import { ColumnMappingType, ISortingItem, Sorter } from '../useAccordionState.typings';
import { getAccordionStateSorter } from './collectionSorter';

export function sortAccordionState<E, S extends ISortingItem<C>, C>(
  data: E[],
  sorting: S[],
  getSortValue: ColumnMappingType<E, C>
) {
  const sorter: Sorter<E, S> = getAccordionStateSorter<E, S, C>(getSortValue);
  return clone(data).sort((a, b) => sorter(a, b, sorting));
}
