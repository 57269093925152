import React, { FC, ReactElement } from 'react';
import { IAreaChartSettings } from '../AreaChart';
import { classNames } from '../utilities';
import Grid from './Grid';

import styles from '../AreaChart.styl';

interface IOwnProps {
  settings: IAreaChartSettings;
}

type Props = IOwnProps;

const GridGroup: FC<Props> = ({ settings }): ReactElement => {
  const className = classNames({
    [styles.gridGroup]: true,
    [styles.zebra]: settings.grid.zebra && !settings.grid.zebraDark,
    [styles.zebraDark]: settings.grid.zebraDark
  });

  const gridCount = settings.grid.count + +settings.grid.hideLastGrid;

  const gridElementList: ReactElement[] = Array
    .from(
      Array(gridCount),
      (_, index: number) => (
        <Grid
          key={index}
          distance={settings.parameters.axisLength * ((gridCount - index) / gridCount)}
          axisCount={settings.parameters.axisCount}
          settings={settings}
        />
      )
    );
  if (settings.grid.hideLastGrid) {
    gridElementList.shift();
  }
  return (
    <g className={className}>
      {gridElementList.map((grid: ReactElement) => grid)}
    </g>
  );
};

export default GridGroup;
