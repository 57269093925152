import { parseFileList } from './parseFileList';

export const parseFileUploadInput = (event: Event): File[] => {
  const target = event.target as HTMLInputElement;
  if (!target) {
    return [];
  }
  const fileList: null | FileList = target.files;
  if (fileList === null || !fileList.length) {
    return [];
  }
  const data: File[] = parseFileList(fileList);

  target.value = '';
  if (!/safari/i.test(navigator.userAgent)) {
    target.type = '';
    target.type = 'file';
  }

  return data;
};
