import React, { FC, ReactElement } from 'react';
import { IAreaChartSettings, ICoordinates } from '../index';
import { getCoordinates, getPoint } from '../utilities';

import styles from '../AreaChart.styl';

interface IOwnProps {
  name: string;
  data: number[];
  settings: IAreaChartSettings;
  mask?: string;
}

type Props = IOwnProps;

const Area: FC<Props> = ({ name, mask, data, settings }): ReactElement => {
  const points: string = Array
    .from(
      Array(data.length),
      (_, index: number) => getCoordinates(
        settings.parameters.baseAngle * index,
        settings.parameters.maxValueDictionary[index]
          ? data[index] / settings.parameters.maxValueDictionary[index] * settings.parameters.axisLength
          : 0
      )
    )
    .map((coordinates: ICoordinates) => `${ getPoint(coordinates.x) },${ getPoint(coordinates.y) }`)
    .join(' ');

  return (
    <polygon
      mask={mask}
      className={`${ name } ${ styles.area }`}
      points={points}
    />
  );
};

export default Area;
