import { Options } from 'select2';
import templateResult from './templateResult';
import transport from './transport';
import processResults from './processResults';
import ajaxData from './ajaxData';
import { IQuestionDropdown } from '@shared/surveyJs/widgets/Dropdown/dropdown.widget';

const getApiSelect2Options = (
  select: HTMLSelectElement,
  isMultiple: boolean,
  _locale: string,
  question: IQuestionDropdown
): Options => {
  return {
    width: '100%',
    containerCssClass: 'dse-dropdown-container',
    dropdownCssClass: 'dse-dropdown-dropdown',
    templateResult: templateResult(select, isMultiple),
    ajax: {
      url: question.apiUrl,
      delay: 250,
      cache: true,
      transport: transport(question),
      data: ajaxData(question.pageSize),
      processResults: processResults(question)
    }
  };
};

export default getApiSelect2Options;
