import { Api } from '@services/api/abstract';
import { Observable } from 'rxjs';
import { INewsItem } from '@services';

const apiUrl = '/api/survey/news';

class NewsApiService extends Api {
  public getNewsCollection(): Observable<INewsItem[]> {
    return this.get<INewsItem[]>({ url: 'public' });
  }
}

export const newsApiService = new NewsApiService(apiUrl);
