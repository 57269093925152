import { addClass } from './cssClassHelpers';

export type AttributeDictionary<T> = {
  [key in keyof T]?: any
};

export type ContentType = undefined | null | number | string | Text | HTMLElement | SVGSVGElement;
export interface ICreateElementOptions<T extends HTMLElement> {
  tagName?: keyof HTMLElementTagNameMap;
  classNames?: string | string[];
  content?: ContentType | ContentType[];
  attributes?: AttributeDictionary<T>;
  dataset?: Record<string, undefined | number | string>;
  styles?: Partial<CSSStyleDeclaration>;
}

export const setElementAttributes = <T extends HTMLElement>(element: T, attributes: AttributeDictionary<T>): T => {
  (Object.keys(attributes) as Array<keyof T>)
    .map((attribute: keyof T) => {
      if (typeof attributes[attribute] !== 'undefined') {
        element[attribute] = attributes[attribute];
      }
    });
  return element;
};

export const setElementDataset = <T extends HTMLElement>(
  element: T,
  dataset: Record<string, undefined | number | string>
): T => {
  Object.keys(dataset)
    .map((key: string) => {
      const value = dataset[key];
      if (typeof value !== 'undefined') {
        element.dataset[key] = `${ value }`;
      }
    });
  return element;
};

export const setElementStyles = <T extends HTMLElement>(element: T, styles: Partial<CSSStyleDeclaration>): T => {
  (Object.keys(styles) as Array<keyof CSSStyleDeclaration>)
    .map((key: keyof CSSStyleDeclaration) => {
      const value = styles[key as keyof CSSStyleDeclaration] as null | string;
      element.style.setProperty(key as string, value);
    });
  return element;
};
export const setElementContent = <T extends HTMLElement>(
  element: T,
  content: ContentType | ContentType[],
  replace: boolean = false
): T => {
  const contentArray: ContentType[] = Array.isArray(content)
    ? content
    : [ content ];
  if (replace) {
    element.innerHTML = '';
  }
  contentArray.map((child: ContentType) => {
    if (typeof child !== 'undefined' && child !== null) {
      element.append(child as string | Node);
    }
  });
  return element;
};

export const createElement = <T extends HTMLElement>(options: ICreateElementOptions<T> = {}): T => {
  const {
    tagName = 'div',
    classNames,
    content,
    attributes,
    dataset,
    styles
  }  = options;
  const element = document.createElement(tagName) as T;
  if (typeof classNames !== 'undefined') {
    const classList: string[] = Array.isArray(classNames)
      ? classNames
        .filter(Boolean)
      : [ classNames ];
    addClass(element, classList);
  }
  if (typeof content !== 'undefined') {
    setElementContent(element, content);
  }
  if (typeof styles !== 'undefined') {
    setElementStyles(element, styles);
  }
  if (typeof attributes !== 'undefined') {
    setElementAttributes(element, attributes);
  }
  if (typeof dataset !== 'undefined') {
    setElementDataset(element, dataset);
  }
  return element;
};
