import { clone } from '@utilities';
import { AccordionStateFilterPredicateType } from '../useAccordionState.typings';
import { checkArrayType } from './checkArrayType';

export function filterAccordionState<E extends unknown[]>(
  collection: E,
  filter: any,
  predicates: Array<AccordionStateFilterPredicateType<any, any>>
) {
  checkArrayType(predicates, 'Predicates isn\'t array type!');
  let tmp = clone(collection);
  predicates.forEach((predicate) => {
    tmp = tmp.filter((item) => predicate(item, filter)) as E;
  });
  return tmp;
}
