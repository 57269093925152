import { FileError, IFileUploadQuestion, IStateItem, Status } from '../fileUpload.typings';
import { AttributeDictionary, createElement } from '../../common';
import { getConvertedSize, getPreviewUrl } from '../utilities';

import { useIcon } from './useIcon';

export const createFileListItemHtml = (
  question: IFileUploadQuestion,
  classNameDictionary: Record<string, string>,
  getLocalization: (question: IFileUploadQuestion, key: string) => string,
  item: IStateItem
): HTMLLIElement => {
  const { data } = item;
  const { name, extension, size, status, errorList } = data;

  const progressbarElement = createElement({
    classNames: [ classNameDictionary.progressBar ],
    styles: { width: `${ data.loadingPercent }%` }
  });

  const attachment = createElement({
    tagName: 'span',
    classNames: [ classNameDictionary.icon, classNameDictionary.attachment ]
  });
  useIcon(attachment, 'attachment');

  const nameElement = createElement({
    tagName: 'span',
    classNames: classNameDictionary.name,
    content: name
  });
  const extensionElement = createElement({
    tagName: 'span',
    classNames: classNameDictionary.extension,
    content: extension
  });
  const fileNameElement = createElement({
    tagName: 'span',
    classNames: classNameDictionary.filename,
    content: [
      attachment,
      nameElement,
      '.',
      extensionElement
    ]
  });

  const sizeElement = createElement({
    tagName: 'span',
    classNames: classNameDictionary.fileSize,
    content: getConvertedSize(size, question, getLocalization)
  });

  const statusElement = createElement({
    tagName: 'span',
    classNames: classNameDictionary.fileStatus,
    content: status === Status.ERROR
      ? errorList[0] === FileError.TYPE
        ? getLocalization(question, 'errorType')
        : errorList[0] === FileError.OVERSIZE
          ? getLocalization(question, 'errorSize')
          : errorList[0] === FileError.DUPLICATE
            ? getLocalization(question, 'errorDuplicate')
            : errorList[0] === FileError.UPLOAD
              ? getLocalization(question, 'errorUpload')
              : getLocalization(question, 'error')
      : status === Status.WAITING || status === Status.LOADING
        ? getLocalization(question, 'loading')
        : status === Status.UPLOADED || status === Status.PRELOADED
          ? getLocalization(question, 'uploaded')
          : getLocalization(question, 'unknown')
  });

  const loadingStatusValueElement = createElement({
    tagName: 'span',
    classNames: classNameDictionary.loadingStatusValue,
    content: data.loadingPercent
  });

  const loadingStatusElement = createElement({
    tagName: 'span',
    classNames: classNameDictionary.loadingStatus,
    content: [
      loadingStatusValueElement,
      '%'
    ]
  });

  const attributes: AttributeDictionary<HTMLAnchorElement> = {};
  attributes.target = '_blank';
  attributes.href = getPreviewUrl(question, item);
  const previewIconButtonElement = createElement<HTMLAnchorElement>({
    tagName: 'a',
    classNames: [
      classNameDictionary.icon,
      classNameDictionary.iconButton,
      classNameDictionary.previewButton,
      ...(![ Status.UPLOADED, Status.PRELOADED ].includes(status)
        ? [ classNameDictionary.hidden ]
        : []
      )
    ],
    attributes
  });
  useIcon(previewIconButtonElement, 'fileSearch');

  const reloadIconButtonElement = createElement({
    tagName: 'span',
    classNames: [
      classNameDictionary.icon,
      classNameDictionary.iconButton,
      classNameDictionary.reloadButton,
      ...(!(status === Status.ERROR && errorList.includes(FileError.UPLOAD)) ? [ classNameDictionary.hidden ] : [])
    ]
  });
  useIcon(reloadIconButtonElement, 'sync');

  let deleteIconButtonElement = null;
  if (!question.isReadOnly) {
    deleteIconButtonElement = createElement({
      tagName: 'span',
      classNames: [ classNameDictionary.icon, classNameDictionary.iconButton, classNameDictionary.deleteButton ]
    });

    useIcon(deleteIconButtonElement, 'trash');
  }

  const actionsElement = createElement({
    tagName: 'span',
    classNames: classNameDictionary.actions,
    content: [
      loadingStatusElement,
      previewIconButtonElement,
      reloadIconButtonElement,
      deleteIconButtonElement
    ]
  });

  const fileInfoElement = createElement({
    tagName: 'span',
    classNames: classNameDictionary.fileInfo,
    content: [
      sizeElement,
      statusElement,
      actionsElement
    ]
  });

  return createElement<HTMLLIElement>({
    tagName: 'li',
    classNames: [
      classNameDictionary.fileListItem,
      ...(status === Status.ERROR ? [ classNameDictionary.error ] : []),
      ...(status === Status.WAITING || status === Status.LOADING ? [ classNameDictionary.loading ] : []),
      ...(status === Status.UPLOADED || status === Status.PRELOADED ? [ classNameDictionary.uploaded ] : [])
    ],
    content: [
      progressbarElement,
      fileNameElement,
      fileInfoElement
    ]
  });
};
