import React, { FC, ReactElement } from 'react';
import { IAreaChartSettings } from '../index';
import { getPoint } from '../utilities';
import Area from './Area';

import styles from '../AreaChart.styl';
import Marker from '@pages/Dashboard/components/AreaChart/components/Marker';

interface IOwnProps {
  settings: IAreaChartSettings;
}

type Props = IOwnProps;

const AreaGroup: FC<Props> = ({ settings }): ReactElement => {
  const areaElementList: ReactElement[] = [];
  const middleOfChart = getPoint(settings.parameters.axisLength);
  Object
    .entries(settings.parameters.areaDictionary)
    .map(
      ([ key, value ]) => {
        if (key === 'value') {
          areaElementList.push((
            <mask
              id="mask"
              key={`mask-${ key }`}
            >
              <rect
                transform={`translate(-${ middleOfChart },-${ middleOfChart })`}
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="white"
              />
              <Area
                name="mask"
                data={value}
                settings={settings}
              />
            </mask>
          ));
          areaElementList.push((
            <Area
              key={key}
              name={key}
              data={value}
              settings={settings}
            />
          ));
        } else {
          areaElementList.push((
            <Area
              mask="url(#mask)"
              key={key}
              name={key}
              data={value}
              settings={settings}
            />
          ));
        }
      }
    );

  let zeroCounter = -1;
  const markerElementList: ReactElement[] = settings.data.showMarkers
    ? settings.parameters.areaDictionary.value
      .map(
        (value: number, index: number): null | ReactElement  => {
          if (!value) {
            zeroCounter++;
            if (zeroCounter) {
              return null;
            }
          }
          return (
            <Marker
              key={index}
              index={index}
              value={value}
              settings={settings}
            />
          );
        }
      )
      .filter((item: null | ReactElement): boolean => item !== null) as ReactElement[]
    : [];
  return (
    <g className={styles.areaGroup}>
      {areaElementList.map((area: ReactElement) => area)}
      {markerElementList.map((marker: ReactElement) => marker)}
    </g>
  );
};

export default AreaGroup;
