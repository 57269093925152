import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CancelSurveyModal.styl';

interface IOwnProps {
  modalText?: string;
  translationKey?: string;
}

type Props = IOwnProps;
export const CancelSurveyModal: FC<Props> = ({ modalText, translationKey = 'survey.cancelText' }) => {
  const { t } = useTranslation();

  const text = typeof modalText !== 'undefined'
    ? modalText
    : t(translationKey);

  return (
    <div className={styles.modal}>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default memo(CancelSurveyModal);
