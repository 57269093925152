import { IProperty } from '../../interfaces';

const resetButtonProperty: IProperty = {
  name: 'resetButton',
  category: 'calendar',
  type: 'switch',
  default: false,
  visible: true,
  visibleIndex: 13
};

export default resetButtonProperty;
