export const isEqual = (first?: any, second?: any, stringify: boolean = false): boolean => {
  if (typeof first === 'undefined') {
    return typeof second === 'undefined';
  }
  if (first === null) {
    return second === null;
  }
  if (typeof second === 'undefined' || second === null) {
    return false;
  }
  return stringify
    ? JSON.stringify(first.toString()) === JSON.stringify(second.toString())
    : JSON.stringify(first) === JSON.stringify(second);
};
