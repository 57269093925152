import React, { FC, ReactElement } from 'react';
import { ITask, ITaskCount, TaskCount, TaskCountDictionary } from '@services';
import { Tabs } from '@shared/ui';
import AssessmentTasksWidget from '../AssessmentTasks/AssessmentTasks';
import BasicTasksWidget from '../BasicTasks/BasicTasks';

interface IOwnProps {
  namespace: string | string[];
  tab: TaskCount;
  changeTabHandler: (value: TaskCount) => void;
  basicTabHeading: ReactElement;
  basicTaskList: ITask[];
  basicTaskLoading: boolean;
  assessmentTabHeading: ReactElement;
  assessmentTaskData: TaskCountDictionary<ITaskCount>;
  assessmentTaskLoading: boolean;
}

type Props = IOwnProps;

const TaskTabs: FC<Props> = (
  {
    namespace,
    tab,
    changeTabHandler,
    basicTabHeading,
    basicTaskList,
    basicTaskLoading,
    assessmentTabHeading,
    assessmentTaskData,
    assessmentTaskLoading
  }
) => {
  return (
    <Tabs
      dataAttributesDictionary={{
        test: { task: 'widget' },
        guide: { task: 'widget' }
      }}
      activeTab={tab}
      onChange={changeTabHandler}
    >
      <section
        data-label={basicTabHeading}
        data-reload={true}
        data-key="BASIC"
      >
        <BasicTasksWidget
          namespace={namespace}
          basicTaskList={basicTaskList}
          loading={basicTaskLoading}
        />
      </section>
      <section
        data-label={assessmentTabHeading}
        data-reload={true}
        data-key="ASSESSMENT"
      >
        <AssessmentTasksWidget
          namespace={namespace}
          assessmentTaskData={assessmentTaskData}
          loading={assessmentTaskLoading}
        />
      </section>
    </Tabs>
  );
};

export default TaskTabs;
