import { IComplianceConfig } from '../ComplianceScoreWidget/ComplianceScoreWidget';

export
const defaultConfig: IComplianceConfig[] = [
  {
    percent: 50,
    color: '#EB5757',
    textColor: '#EB5757',
    status: 'insufficient'
  },
  {
    percent: 12.5,
    color: '#F2994A',
    textColor: '#F2994A',
    status: 'poor'
  },
  {
    percent: 12.5,
    color: '#F2C94C',
    textColor: '#F2C94C',
    status: 'fair'
  },
  {
    percent: 12.5,
    color: '#6FCF97',
    textColor: '#6FCF97',
    status: 'good'
  },
  {
    percent: 12.5,
    color: '#27AE60',
    textColor: '#27AE60',
    status: 'excellent'
  }
];

export const undeterminedConfig: Partial<IComplianceConfig> = {
  color: '#ececec',
  textColor: '#828282',
  status: 'undetermined'
};
