import { IProperty } from '../../interfaces';

const shortModeProperty: IProperty = {
  name: 'shortMode',
  category: 'calendar',
  type: 'switch',
  default: false,
  visible: true,
  visibleIndex: 4
};

export default shortModeProperty;
