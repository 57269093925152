import { Options } from 'select2';
import { getSorter } from '@utilities';
import { templateSelection } from './templateSelection';
import { templateResult } from './templateResult';

export const getCountryCodeDropdownSettings = ($element: JQuery<HTMLSelectElement>, locale: string): Options => ({
  allowClear: false,
  placeholder: '',
  containerCssClass: 'dse-phone-dropdown-container',
  dropdownCssClass: 'dse-phone-dropdown-dropdown',
  templateSelection,
  templateResult: templateResult($element),
  sorter: getSorter({ locale, compareById: false })
});
