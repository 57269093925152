import { IFileUploadQuestion } from '../fileUpload.typings';
import { createElement } from '../../common';
import { getAcceptedFileExtensionsAttributeValue } from '../utilities';

export const dropZoneInputFile = (
  id: string,
  question: IFileUploadQuestion,
  classNameDictionary: Record<string, string>
) => createElement<HTMLInputElement>({
  tagName: 'input',
  classNames: classNameDictionary.dropZoneInput,
  attributes: {
    id,
    type: 'file',
    multiple: (typeof question.maxFileCount !== 'undefined' && question.maxFileCount !== 1)
      || (typeof question.minFileCount !== 'undefined' && question.minFileCount > 1),
    accept: getAcceptedFileExtensionsAttributeValue(question.acceptedTypes)
  }
});
