import { Options } from 'select2';
import countryTemplateResult from './countryTemplateResult';
import countryTemplateSelection from './countryTemplateSelection';
import { getSorter } from '@utilities';

const getCountrySelect2Options = (
  select: HTMLSelectElement,
  isMultiple: boolean,
  locale: string
): Options => {
  return {
    containerCssClass: 'dse-country-dropdown-container',
    dropdownCssClass: 'dse-country-dropdown-dropdown',
    templateSelection: countryTemplateSelection,
    templateResult: countryTemplateResult(select, isMultiple),
    sorter: getSorter({ locale, compareById: false })
  };
};

export default getCountrySelect2Options;
