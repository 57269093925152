class FileUploadData {
  public getAsFormData(data: { [key: string]: any }) {
    const fd = new FormData();
    for (const [ key, value ] of Object.entries(data)) {
      if (Array.isArray(value)) {
        value.forEach((v) => fd.append(key, v));
      } else {
        fd.append(key, value);
      }
    }
    return fd;
  }
}
export const fileUploadData = new FileUploadData();
