import { FunctionFactory, Survey, Question } from '@shared/surveyJs/reexport';
import { isInDictionary } from './isInDictionary';

export interface ICustomFunctionContext {
  returnResult: (value: any) => void;
  func: (parameters: any[]) => any;
  question: Question;
  survey: Survey;
}

export type isInDictionaryParameters = [undefined | null | string | string[], string, boolean?, boolean?];

function isInDictionaryWrapper(this: ICustomFunctionContext, parameters: any[]): any {
  const context = this;
  const [ value, dictionaryName, every = false, fallback = false ] = parameters as isInDictionaryParameters;
  if (!dictionaryName || !value || Array.isArray(value) && !value.length) {
    return context.returnResult(fallback);
  }
  isInDictionary(context, dictionaryName, value, every);
}

FunctionFactory
  .Instance
  .register('isInDictionary', isInDictionaryWrapper, true);
