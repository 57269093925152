import React, { FC, ReactElement } from 'react';
import { ICoordinates } from '../index';
import { getPoint } from '../utilities';

import styles from '../AreaChart.styl';

interface IOwnProps {
  width: number;
  height: number;
  text: string;
  className: string;
  coordinates: ICoordinates;
}

type Props = IOwnProps;

const Label: FC<Props> = ({ width, height, text, className, coordinates }): ReactElement => {
  return (
    <foreignObject
      width={width}
      height={height}
      x={getPoint(coordinates.x - width / 2)}
      y={getPoint(coordinates.y - height / 2)}
    >
      <div className={`${ styles.label } ${ className }`}>{text}</div>
    </foreignObject>
  );
};

export default Label;
