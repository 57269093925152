import { IFileUploadQuestion } from '../fileUpload.typings';
import { createElement } from '../../common';
import { createPanelHtml } from './createPanelHtml';

export const createFileBlockHtml = (
  question: IFileUploadQuestion,
  classNameDictionary: Record<string, string>,
  getLocalization: (question: IFileUploadQuestion, key: string) => string
): HTMLElement => createElement({
  classNames: [ classNameDictionary.fileBlock, classNameDictionary.hidden ],
  content: [
    createPanelHtml(
      question,
      classNameDictionary,
      getLocalization
    ),
    createElement({
      tagName: 'ul',
      classNames: classNameDictionary.fileList
    })
  ]
});
