import { IProperty } from '../../interfaces';

const shortcutsProperty: IProperty = {
  name: 'shortcuts',
  category: 'calendar',
  type: 'itemvalue[]',
  default: false,
  visible: true,
  visibleIndex: 100
};

export default shortcutsProperty;
