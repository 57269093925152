import { IProperty } from '../../interfaces';
import { QuestionDropdownModel } from '@shared/surveyJs/reexport';

const optionsCaptionProperty: IProperty = {
  name: 'optionsCaption',
  alternativeName: 'placeholder',
  category: 'general',
  type: 'string',
  isLocalizable: true,
  nextToProperty: 'label',
  visibleIndex: 4,
  dependsOn: 'showPlaceholder',
  visibleIf: (question: QuestionDropdownModel) => question.showPlaceholder === true
};

export default optionsCaptionProperty;
