import {
  AssetFormKey,
  AssetTypeKey,
  DataStorageFormKey,
  SoftwareFormKey,
  ThirdPartyFormKey
} from '../assetManagement.typings';
import {
  dataStorageSteps,
  softwareSteps,
  thirdPartySteps
} from '../data';

const getNextItem = <T>(array: T[], currentStep: T): T | null => {
  const currentIndex = array.indexOf(currentStep);
  if (currentIndex === -1) {
    return null;
  }
  if (currentIndex === array.length - 1) {
    return null;
  }
  return array[currentIndex + 1];
};

export function getNextStep(type: 'DataStorage', currentStep: DataStorageFormKey): null | DataStorageFormKey;
export function getNextStep(type: 'Software', currentStep: SoftwareFormKey): null | SoftwareFormKey;
export function getNextStep(type: 'ThirdParty', currentStep: ThirdPartyFormKey): null | ThirdPartyFormKey;
export function getNextStep(type: AssetTypeKey, currentStep: AssetFormKey): null | AssetFormKey {
  switch (type) {
    case 'DataStorage':
      return getNextItem<DataStorageFormKey>(dataStorageSteps, currentStep as DataStorageFormKey);
    case 'Software':
      return getNextItem<SoftwareFormKey>(softwareSteps, currentStep as SoftwareFormKey);
    case 'ThirdParty':
      return getNextItem<ThirdPartyFormKey>(thirdPartySteps, currentStep as ThirdPartyFormKey);
    default:
      return null;
  }
}
