import { flatten } from './flatten';
import { parseDirectory } from './parseDirectory';

export const parseDataTransferItemList = (
  dataTransferItemList: DataTransferItemList,
  ignoreHiddenFiles: boolean = true
): Promise<File[]> => {
  return new Promise<File[]>((resolve: (fileList: File[]) => void) => {
    const result: Array<Promise<File | File[]>> = [];
    for (const dataTransferItem of dataTransferItemList) {
      if (dataTransferItem.webkitGetAsEntry != null) {
        const entry = dataTransferItem.webkitGetAsEntry();
        if (entry.isFile) {
          const file = dataTransferItem.getAsFile();
          if (file) {
            result.push(Promise.resolve(file));
          }
        } else if (entry.isDirectory) {
          const fileList = parseDirectory(entry, ignoreHiddenFiles);
          result.push(fileList);
        }
      } else if (dataTransferItem.getAsFile !== null) {
        if (dataTransferItem.kind === null || dataTransferItem.kind === 'file') {
          const file = dataTransferItem.getAsFile();
          if (file) {
            result.push(Promise.resolve(file));
          }
        }
      }
    }
    Promise.all(result)
      .then(
        (data: Array<File | File[]>) => {
          resolve(flatten(data));
        }
      );
  });
};
