import React, { FC, ReactElement } from 'react';
import { getCoordinates, getPoint } from '../utilities';

import styles from '../AreaChart.styl';

interface IOwnProps {
  angle: number;
  length: number;
  className?: string;
}

type Props = IOwnProps;

const Axis: FC<Props> = ({ angle, length, className = styles.axis }): ReactElement => {
  const coordinates = getCoordinates(angle, length);
  return (
    <line
      className={className}
      x1={0}
      y1={0}
      x2={getPoint(coordinates.x)}
      y2={getPoint(coordinates.y)}
    />
  );
};

export default Axis;
