import { IProperty } from '../../interfaces';

const allowClearProperty: IProperty = {
  name: 'allowClear',
  category: 'choices',
  type: 'switch',
  default: true,
  visible: true
};

export default allowClearProperty;
