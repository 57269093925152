import { IProperty } from '../../interfaces';

const clearButtonProperty: IProperty = {
  name: 'clearButton',
  category: 'calendar',
  type: 'switch',
  default: true,
  visible: true,
  visibleIndex: 11
};

export default clearButtonProperty;
