import React, { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface IOwnProps {
  namespace: string | string[];
  translationKey: string;
  expanded?: boolean;
}

type Props = IOwnProps;

const InfoAccordionPanel: FC<Props> = ({ namespace, translationKey }): ReactElement => {
  const { t } = useTranslation(namespace);
  return <div>{t(translationKey)}</div>;
};

export default memo(InfoAccordionPanel);
