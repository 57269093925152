import { Observable } from 'rxjs';
import { IAction } from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/survey';

class ActionApiService extends Api {
  public getActionNameTranslations(id: number): Observable<IAction> {
    return this.get<IAction>({ url: `action/${ id }` });
  }
}

export const actionApiService = new ActionApiService(apiUrl);
