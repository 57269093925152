import { flatten } from './flatten';

export const parseDirectory = (
  directory: FileSystemDirectoryEntry,
  ignoreHiddenFiles: boolean = true
): Promise<File[]> => {
  const reader = directory.createReader();
  return new Promise<File[]>((resolve: (fileList: File[]) => void) => {
    reader
      .readEntries((entries: FileSystemEntry[]) => {
        const result: Array<Promise<null | File | File[]>> = [];
        for (const entry of entries) {
          if (entry.isFile) {
            result.push(new Promise<null | File>((resolveFile: (file: null | File) => void) => {
              (entry as FileEntry)
                .file((file: File) => {
                  if (ignoreHiddenFiles && file.name.substring(0, 1) === '.') {
                    resolveFile(null);
                  }
                  resolveFile(file);
                });
            })
            );
          }
          if (entry.isDirectory) {
            result.push(parseDirectory(entry as FileSystemDirectoryEntry, ignoreHiddenFiles));
          }
        }
        Promise.all(result)
          .then(
            (data: Array<null | File | File[]>) => {
              const flatArray: Array<null | File> = flatten(data);
              const fileArray: File[] = flatArray.filter((item: null | File | File[]) => item !== null) as File[];
              resolve(fileArray);
            }
          );
      });
  });
};
