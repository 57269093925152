import { useEffect, useState } from 'react';
import { getKey } from '@router';
import {
  AccordionStateFilterPredicateType,
  AccordionStateSetStateType,
  ColumnMappingType,
  FilterPredicatesSetStateType,
  IAccordionState,
  IInitialData,
  ISortingItem
} from './useAccordionState.typings';
import {
  checkArrayType,
  getFilter,
  getSorting,
  filterAccordionState,
  filterAndPredicatesExist,
  sortAccordionState,
  sortingAndGetSortValue
} from './utilities';
import { updateFilter } from './utilities/updateFilter';
import { updateSorting } from './utilities/updateSorting';

export const useAccordionState = <E, F, S extends ISortingItem<C>, C>({
  initialData = [],
  id,
  filtersStorageKey,
  defaultFilter,
  initialFilterPredicates,
  sortingStorageKey,
  defaultSorting = [],
  getSortValue
}: IInitialData<E, F, S, C>): IAccordionState<E, F, S> => {
  const filtersKey = filtersStorageKey
    ? getKey(filtersStorageKey)
    : undefined;
  const sortingKey = sortingStorageKey
    ? getKey(sortingStorageKey)
    : undefined;
  const [ initialDataList, setInitialData ] = useState<E[]>(initialData);
  const [ data, setData ] = useState<E[]>(initialData);
  const [ filter, setFilter ] = useState<undefined | F>(getFilter(filtersKey, id, defaultFilter));
  const [ sorting, setSorting ] = useState<undefined | S[]>(getSorting(sortingKey, id, defaultSorting));
  let filterPredicates: Array<AccordionStateFilterPredicateType<E, F>> | undefined = initialFilterPredicates;
  const setFilterPredicates: FilterPredicatesSetStateType<E, F> = (collection) => (filterPredicates = collection);

  useEffect(
    () => {
      useEffectHandler(initialDataList, filter, filterPredicates, sorting, getSortValue, setData);
    },
    [ initialDataList ]
  );

  useEffect(
    () => {
      useEffectHandler(initialDataList, filter, filterPredicates, sorting, getSortValue, setData);
      if (!!filtersKey && !!id && typeof filter !== 'undefined') {
        updateFilter(filtersKey, id, filter);
      }
    },
    [ filter ]
  );

  useEffect(
    () => {
      useEffectHandler(initialDataList, filter, filterPredicates, sorting, getSortValue, setData);
      if (!!sortingKey && !!id && typeof sorting !== 'undefined') {
        updateSorting(sortingKey, id, sorting);
      }
    },
    [ sorting ]
  );

  return {
    data,
    setData: setInitialData,
    filter,
    setFilter,
    setFilterPredicates,
    sorting,
    setSorting
  };
};

function useEffectHandler<E, F, S extends ISortingItem<C>, C>(
  initialDataList: E[],
  filter: undefined | F,
  filterPredicates: Array<AccordionStateFilterPredicateType<E, F>> | undefined,
  sorting: undefined | S[],
  getSortValue: ColumnMappingType<E, C> | undefined,
  setData: AccordionStateSetStateType<E>
) {
  checkArrayType(initialDataList, 'Collection isn\'t array type!');
  let result = initialDataList;
  if (filterAndPredicatesExist(filter, filterPredicates)) {
    result = filterAccordionState(initialDataList, filter, filterPredicates!);
  }
  if (sortingAndGetSortValue(sorting, getSortValue)) {
    result = sortAccordionState<E, S, C>(result, sorting!, getSortValue!);
  }
  setData(result);
}
