import { OptionDataType } from '@interfaces';
import { IdTextPair, OptGroupData, OptionData } from 'select2';
import { i18n } from '@services';

export enum SortDirection {
  asc = 1,
  none = 0,
  random = 0.5,
  desc = -1,
}

export function isOptGroupData(
  item: OptionDataType
): item is OptGroupData {
  return typeof (item as IdTextPair | OptionData).id === undefined;
}

export interface ISorterOptions {
  locale?: null | string;
  compareById?: boolean;
  sortDirection?: SortDirection;
  topItemList?: string[];
  topItemDirection?: SortDirection;
  bottomItemList?: string[];
  bottomItemDirection?: SortDirection;
}

export interface ISorterSettings {
  locale: null | string;
  compareById: boolean;
  sortDirection: SortDirection;
  topItemList: string[];
  topItemDirection: SortDirection;
  bottomItemList: string[];
  bottomItemDirection: SortDirection;
}

const defaultSorterSettings: ISorterSettings = {
  locale: null,
  compareById: false,
  sortDirection: SortDirection.asc,
  topItemList: [],
  topItemDirection: SortDirection.asc,
  bottomItemList: [],
  bottomItemDirection: SortDirection.asc
};

const localesDictionary: Record<string, string> = {
  en: 'en-GB',
  de: 'de-DE'
};

const getLocale = (locale: string | null): string => locale === null
  ? localesDictionary[i18n.language]
  : locale;

const getRandomValue = () => Math.random() >= 0.5 ? 1 : -1;

const sortItemList = (
  a: string,
  b: string,
  itemList: string[],
  direction: SortDirection,
  sortDirection: SortDirection,
  collator: Intl.Collator
): null | number => {
  if (direction === 0.5) {
    return getRandomValue();
  }
  if (itemList.includes(a) && itemList.includes(b)) {
    return collator.compare(a, b) * sortDirection;
  } else if (itemList.includes(a)) {
    return direction;
  } else if (itemList.includes(b)) {
    return -1 * direction;
  }
  return null;
};

export const getSorter = (options: ISorterOptions = {}) => (data: OptionDataType[]) => {
  const settings: ISorterSettings = { ...defaultSorterSettings, ...options };
  const collatorOptions: Intl.CollatorOptions = {
    numeric: true,
    usage: 'sort',
    sensitivity: 'variant',
    caseFirst: 'upper'
  };
  const collator = new Intl.Collator(getLocale(settings.locale), collatorOptions);
  return data
    .sort(
      (a: OptionDataType, b: OptionDataType): number => {
        const aIdValue = isOptGroupData(a) ? a.text : a.id;
        const aValue = settings.compareById
          ? aIdValue
          : a.text;
        const bIdValue = isOptGroupData(b) ? b.text : b.id;
        const bValue = settings.compareById
          ? bIdValue
          : b.text;
        if (settings.topItemList.length) {
          const value = sortItemList(
            aIdValue,
            bIdValue,
            settings.topItemList,
            SortDirection.desc,
            settings.topItemDirection,
            collator
          );
          if (value !== null) {
            return value;
          }
        }
        if (settings.bottomItemList.length) {
          const value = sortItemList(
            aIdValue,
            bIdValue,
            settings.bottomItemList,
            SortDirection.asc,
            settings.bottomItemDirection,
            collator
          );
          if (value !== null) {
            return value;
          }
        }
        const direction: number = settings.sortDirection;
        return direction === 0.5
          ? getRandomValue()
          : collator.compare(aValue, bValue) * direction;
      }
    );
};
