import { IComponentPromiseFactory } from '@router';

export const routeComponents: Record<string, IComponentPromiseFactory<any>> = {
  DocumentError: () => import(/* webpackChunkName: "DocumentError" */ '@pages/Error/DocumentError/DocumentError'),
  ErrorPage: () => import(/* webpackChunkName: "ErrorPage" */ '@pages/Error/ErrorPage/ErrorPage'),

  DpoNomination: () => import(/* webpackChunkName: "DpoNomination" */ '@pages/DpoNomination/DpoNominationPage'),
  DpoPrivacyPolicy: () => import(/* webpackChunkName: "DpoPrivacyPolicy" */ '@pages/DpoPrivacyPolicy/DpoPrivacyPolicyPage'),
  DpoNotification: () => import(/* webpackChunkName: "DpoNotification" */ '@pages/DpoNotification/DpoNotificationPage'),

  CustomProcesses: () => import(/* webpackChunkName: "CustomProcesses" */ '@pages/CustomProcesses/CustomProcesses'),
  ProcessSelection: () => import(/* webpackChunkName: "ProcessSelection" */ '@pages/ProcessSelection/ProcessSelectionPage'),
  SurveyTask: () => import(/* webpackChunkName: "SurveyTask" */ '@pages/SurveyTask/SurveyTaskPage'),

  Dashboard: () => import(/* webpackChunkName: "Dashboard" */ '@pages/Dashboard/DashboardPage'),

  Analytics: () => import(/* webpackChunkName: "Analytics" */ '@pages/Analytics/AnalyticsPage'),

  Processes: () => import(/* webpackChunkName: "Processes" */ '@pages/Processes/ProcessesPage'),

  Tom: () => import(/* webpackChunkName: "Tom" */ '@pages/Tom/Tom'),

  Risk: () => import(/* webpackChunkName: "Risk" */ '@pages/Risk/RiskPage'),
  RiskSurvey: () => import(/* webpackChunkName: "RiskSurvey" */ '@pages/RiskSurvey/RiskSurvey'),

  Document: () => import(/* webpackChunkName: "Document" */ '@pages/Document/DocumentPage'),

  Static: () => import(/* webpackChunkName: "Static" */ '@pages/Static/Static'),

  DataBreach: () => import(/* webpackChunkName: "DataBreach" */ '@pages/DataBreach/DataBreachPage'),
  DataBreachSurvey: () => import(/* webpackChunkName: "DataBreachSurvey" */ '@pages/DataBreachSurvey/DataBreachSurvey'),

  CourseManagement: () => import(/* webpackChunkName: "CourseManagement" */ '@pages/CourseManagement/CourseManagementPage'),
  MyCourses: () => import(/* webpackChunkName: "CourseManagement" */ '@pages/MyCourses/MyCourses'),

  Team: () => import(/* webpackChunkName: "Team" */ '@pages/Team/TeamPage'),
  TeamDetails: () => import(/* webpackChunkName: "TeamDetails" */ '@pages/TeamDetails/TeamDetailsPage'),

  Systems: () => import(/* webpackChunkName: "Systems" */ '@pages/Systems/Systems'),
  SystemsList: () => import(/* webpackChunkName: "SystemsList" */ '@pages/SystemsList/SystemsList'),
  SystemsDetails: () => import(/* webpackChunkName: "SystemsDetails" */ '@pages/SystemsDetails/SystemsDetailsPage'),

  AssetManagement: () => import(/* webpackChunkName: "AssetManagement" */ '@pages/AssetManagement/AssetManagementPage'),

  Stub: () => import(/* webpackChunkName: "SystemsDetails" */ '@pages/Stub/Stub')
};
