import DropdownWidget from './dropdown.widget';
import {
  allowAddNewTagProperty,
  allowClearProperty,
  dropdownAutoWidthProperty,
  hasNoneProperty,
  hasOtherProperty,
  hasSelectAllProperty,
  labelProperty,
  maxSelectedChoicesProperty,
  minimumResultsForSearchProperty,
  noneTextProperty,
  optionsCaptionProperty,
  otherErrorTextProperty,
  otherPlaceHolderProperty,
  otherTextProperty,
  select2ConfigProperty,
  selectAllTextProperty,
  showPlaceholderProperty
} from './properties';
import { IProperty, IWidgetConfiguration } from '../interfaces';
import { CustomWidgetCollection, Serializer } from '../../reexport';

import { faSquareCaretDown } from '@fortawesome/pro-regular-svg-icons';
import { faTags } from '@fortawesome/pro-regular-svg-icons';
import './styles/dropdown.styl';

Serializer.removeProperty('dropdown', 'placeholder');
const Dropdown = ($: JQueryStatic): void => {
  const singleName: string = 'dropdown';
  const multipleName: string = 'dropdownmultiple';
  const singleAlternativeNameList: string[] = [ 'select2' ];
  const multipleAlternativeNameList: string[] = [ 'tagbox' ];
  const hidePropertyNameList: string[] = [
    'allowAddNewTag',
    'select2Config'
  ];
  const singleHidePropertyNameList: string[] = [
    ...hidePropertyNameList
  ];
  const multipleHidePropertyNameList: string[] = [
    ...hidePropertyNameList,
    'colCount'
  ];
  const customPropertiesList: IProperty[] = [
    showPlaceholderProperty,
    optionsCaptionProperty,
    labelProperty,
    allowClearProperty,
    allowAddNewTagProperty,
    select2ConfigProperty,
    hasOtherProperty,
    otherTextProperty,
    otherPlaceHolderProperty,
    otherErrorTextProperty,
    dropdownAutoWidthProperty
  ];
  const singleCustomPropertiesList: IProperty[] = [
    minimumResultsForSearchProperty
  ];
  const multipleCustomPropertiesList: IProperty[] = [
    maxSelectedChoicesProperty,
    hasNoneProperty,
    noneTextProperty,
    hasSelectAllProperty,
    selectAllTextProperty
  ];
  const classNameDictionary = {
    select: 'p360Select',
    textarea: 'p360Textarea',
    textareaWrap: 'question-other-label-wrap',
    searchField: 'select2-search__field'
  };
  const editorLocalization = {
    pe: {
      optionsCaption: {
        en: 'Placeholder',
        de: 'Platzhalter'
      },
      showPlaceholder: {
        en: 'Show placeholder',
        de: 'Platzhalter anzeigen'
      },
      dropdownAutoWidth: {
        en: 'Dropdown auto width',
        de: 'Automatische Dropdown-Breite'
      },
      minimumResultsForSearch: {
        en: 'Minimum results for search',
        de: 'Minimale Ergebnisse für die Suche'
      }
    }
  };
  const singleEditorLocalization = {
    qt: {
      [singleName]: {
        en: 'Dropdown single',
        de: 'Dropdown-Liste Single'
      }
    }
  };
  const multipleEditorLocalization = {
    qt: {
      [multipleName]: {
        en: 'Dropdown multiple',
        de: 'Dropdown-Liste Multiple'
      }
    }
  };
  const singleDefaultJSON = {
    type: singleName,
    choices: []
  };
  const multipleDefaultJSON = {
    type: multipleName,
    choices: []
  };
  const singleConfiguration: IWidgetConfiguration = {
    name: singleName,
    iconName: singleName,
    icon: faSquareCaretDown,
    alternativeNameList: singleAlternativeNameList,
    hidePropertyNameList: singleHidePropertyNameList,
    customPropertiesList: [ ...customPropertiesList, ...singleCustomPropertiesList ],
    classNameDictionary,
    editorLocalization: { ...editorLocalization, ...singleEditorLocalization },
    defaultJSON: singleDefaultJSON,
    isMultiple: false,
    inheritClass: 'dropdownbase'
  };
  const multipleConfiguration: IWidgetConfiguration = {
    name: multipleName,
    iconName: multipleName,
    icon: faTags,
    alternativeNameList: multipleAlternativeNameList,
    hidePropertyNameList: multipleHidePropertyNameList,
    customPropertiesList: [ ...customPropertiesList, ...multipleCustomPropertiesList ],
    classNameDictionary,
    editorLocalization: { ...editorLocalization, ...multipleEditorLocalization },
    defaultJSON: multipleDefaultJSON,
    isMultiple: true,
    inheritClass: 'checkbox'
  };
  const singleWidget = new DropdownWidget($, singleConfiguration);
  CustomWidgetCollection.Instance.addCustomWidget(
    singleWidget,
    singleWidget.activatedBy
  );
  const multipleWidget = new DropdownWidget($, multipleConfiguration);
  CustomWidgetCollection.Instance.addCustomWidget(
    multipleWidget,
    multipleWidget.activatedBy
  );
};

export default Dropdown;
