import { Route } from 'router5';
import { DoneFn } from 'router5/dist/types/base';
import { combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { redirectParameterName } from '@router';
import getPageType from '@router/utilities/getPageType';
import { userService } from '@services';

export const nextRoute = new BehaviorSubject<string>('');
export const nextPath = new BehaviorSubject<string>('');

export const setDefaultMiddleware = () => (
  toState: Route & { params: Record<string, string> },
  fromState: Route,
  done: DoneFn
): void => {
  nextRoute.next(toState.name);
  nextPath.next(toState.path);
  const unsubscribe$ = new Subject<void>();
  combineLatest([ userService.isInitialized$, userService.isLoggedIn$, userService.error$ ])
    .pipe(takeUntil(unsubscribe$))
    .subscribe(([ isInitialized, isLoggedIn, error ]: [boolean, boolean, null | number]) => {
      if (isInitialized) {
        unsubscribe$.next();
        unsubscribe$.complete();
        if (error !== null) {
          unsubscribe$.next();
          unsubscribe$.complete();
          const routeState = {
            error,
            data: {
              namespace: '',
              titleTranslationKey: null
            },
            isRouted: fromState !== null,
            pageType: getPageType(toState.name),
            isLoggedIn: false
          };
          const redirected = typeof toState.params[redirectParameterName] !== 'undefined'
            ? { [redirectParameterName]: toState.params[redirectParameterName] }
            : {};
          const state = { ...routeState, ...toState, ...redirected };
          done(null, state);
        } else {
          const routeState = {
            error: null,
            data: {
              namespace: '',
              titleTranslationKey: null
            },
            isRouted: fromState !== null,
            pageType: getPageType(toState.name),
            isLoggedIn
          };
          const redirected = typeof toState.params[redirectParameterName] !== 'undefined'
            ? { [redirectParameterName]: toState.params[redirectParameterName] }
            : {};
          if (toState.params.path) {
            const queryIndex = toState.params.path.indexOf('?');
            if (queryIndex > -1) {
              const queryParams = toState.params.path.slice(queryIndex + 1)
                .split('&')
                .reduce(
                  (result: Record<string, string>, item: string) => ({
                    ...result,
                    ...{
                      [item.split('=')[0]]: item.split('=')[1]
                    }
                  }),
                  {}
                );
              toState.params = { ...toState.params, ...queryParams };
            }
          }
          const state = { ...routeState, ...toState, ...redirected };
          done(null, state);
        }
      }
    });
};
