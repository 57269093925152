import { isValidNumber } from 'libphonenumber-js';
import {
  JsonObject,
  SurveyValidator,
  ValidatorResult,
  surveyLocalization
} from '@shared/surveyJs/reexport';

export interface IPhoneData {
  countryDialCode: string;
  phoneNumber: string;
}

const validatorName = 'phonevalidator';
export const setupPhoneValidator = () => {
  class PhoneValidator extends SurveyValidator {
    constructor() {
      super();
    }

    public getType(): string {
      return 'phonevalidator';
    }

    public validate(data: null | IPhoneData): ValidatorResult {
      if (!data) {
        return (null as unknown) as ValidatorResult;
      }
      const { countryDialCode = '', phoneNumber = '' } = data;
      const value = `${ countryDialCode }${ phoneNumber }`;
      if (isValidNumber(`+${ value }`)) {
        return (null as unknown) as ValidatorResult;
      }
      return new ValidatorResult(value, this.createCustomError(this.getErrorText()));
    }

    protected getErrorText(): string {
      return this.text || this.getDefaultErrorText();
    }

    protected getDefaultErrorText(): string {
      return surveyLocalization.getString('phoneErrorText');
    }
  }

  (window as any).PhoneValidator = PhoneValidator;

  JsonObject.metaData.addClass(
    validatorName,
    [],
    () => new PhoneValidator(),
    'surveyvalidator'
  );

  JsonObject.metaData.addProperties(
    validatorName,
    [
      {
        name: 'PhoneErrorText',
        type: 'string',
        serializationProperty: 'locPhoneErrorText',
        visible: false
      }
    ]);
};
