import { State } from 'router5';
import { IRouteState } from '../interfaces';

export const onPageChangeMiddleware = () => (toState: State): boolean => {
  const { isRouted } = toState as IRouteState;
  if (!isRouted) {
    return true;
  }
  const element = document.querySelector('.p360-scrollbar-content');
  if (element) {
    element.scrollTop = 0;
  }
  return true;
};
