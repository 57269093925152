import React, { FC } from 'react';

import { AreaGroup, AxisGroup, DataGroup, GridGroup } from './components';
import { arrayRotate, getBaseAngle } from './utilities';

import styles from './AreaChart.styl';

export interface IAreaChartData {
  label: string;
  link: string;
  [key: string]: number | string;
}

interface IAreaChartAxisOptions {
  axisNameLink: boolean;
  showAxisValue: boolean;
}

interface IAreaChartDataOptions {
  isLoading: boolean;
  sort: boolean;
  useRelativeValues: boolean;
  showMarkers: boolean;
}

interface IAreaChartGridOptions {
  count: number;
  hideLastGrid: boolean;
  zebra: boolean;
  zebraDark: boolean;
}

export interface IAreaChartOptions {
  axis: IAreaChartAxisOptions;
  data: IAreaChartDataOptions;
  grid: IAreaChartGridOptions;
}

interface IAreaChartParameters {
  size: number;
  axisCount: number;
  axisLength: number;
  baseAngle: number;
  areaDictionary: Record<string, number[]>;
  maxValueDictionary: number[];
  data: IAreaChartData[];
}
export interface IAreaChartSettings extends IAreaChartOptions {
  parameters: IAreaChartParameters;
}

export interface ICoordinates {
  x: number;
  y: number;
}

interface IOwnProps {
  size?: number;
  data: IAreaChartData[];
  options?: Partial<IAreaChartOptions>;
}

type Props = IOwnProps;

const AreaChart: FC<Props> = ({ data, size = 500, options = {} }) => {
  const defaultOptions: IAreaChartOptions = {
    axis: {
      axisNameLink: true,
      showAxisValue: true
    },
    data: {
      isLoading: false,
      sort: true,
      useRelativeValues: true,
      showMarkers: true
    },
    grid: {
      count: 5,
      hideLastGrid: false,
      zebra: true,
      zebraDark: true
    }
  };
  const sortData = (initialData: IAreaChartData[]): IAreaChartData[] => {
    const dataLength = initialData.length;
    let result: IAreaChartData[] = [];
    initialData.sort((a: IAreaChartData, b: IAreaChartData) => {
      const aTotal = +a.total;
      const bTotal = +b.total;
      const aValue = aTotal ? +a.value / aTotal : 0;
      const bValue = bTotal ? +b.value / bTotal : 0;
      return aValue - bValue || aTotal - bTotal;
    });
    result.push(initialData.pop() as IAreaChartData);
    while (initialData.length) {
      result[initialData.length % 2 === 0 ? 'push' : 'unshift'](initialData.pop() as IAreaChartData);
    }
    Array
      .from(Array(Math.ceil(dataLength / 2)))
      .map(() => result = arrayRotate(result));
    return result;
  };
  const optionsSort = options.data?.sort;
  const sort = typeof optionsSort === 'undefined' ? defaultOptions.data.sort : optionsSort;
  const sortedData = sort ? sortData([ ...data ]) : data;
  const areaDictionary: Record<string, number[]> = sortedData
    .reduce(
      (result: Record<string, number[]>, item: IAreaChartData) => {
        Object
          .keys(item)
          .filter((key: string) => key !== 'label')
          .map((key: string) => {
            if (typeof result[key] === 'undefined') {
              result[key] = [];
            }
            const value = item[key] as number;
            result[key].push(value);
          });
        return result;
      },
      {}
    );

  const maxValueDictionary = Array
    .from(
      Array(sortedData.length),
      (_, index: number) => Math.max(
        ...Object.keys(areaDictionary)
          .map((key: string) => areaDictionary[key][index])
      )
    )
    .map(
      (value: number, _index: number, array: number[]) => options?.data?.useRelativeValues
      || defaultOptions.data.useRelativeValues
        ? value
        : Math.max(...array)
    );

  const verticalPadding = 80;
  const labelPadding = 260;
  const chartSize = size - labelPadding;

  const parameters = {
    data: sortedData,
    size: chartSize,
    axisCount: sortedData.length,
    axisLength: chartSize / 2,
    baseAngle: getBaseAngle(sortedData.length),
    areaDictionary,
    maxValueDictionary
  };
  const settings: IAreaChartSettings = Object.assign({}, defaultOptions, options, { parameters });

  const middleOfChart = (chartSize / 2).toFixed(4);
  const dataGroup = !settings.data.isLoading
    ? (
      <>
        <AreaGroup settings={settings} />
        <DataGroup settings={settings} />
      </>
    )
    : null;
  return (
    <div className={styles.chart}>
      <svg
        version="1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`-${ labelPadding / 2 } -${ verticalPadding / 2 } ${ chartSize + labelPadding } ${ chartSize + verticalPadding }`}
      >
        <g transform={`translate(${ middleOfChart },${ middleOfChart })`}>
          <GridGroup settings={settings} />
          <AxisGroup settings={settings} />
          {dataGroup}
        </g>
      </svg>
    </div>
  );
};

export default AreaChart;
