import { IProperty } from '../../interfaces';

const labelProperty: IProperty = {
  name: 'label',
  category: 'general',
  type: 'string',
  default: '',
  isLocalizable: true,
  visible: true,
  visibleIndex: 3
};

export default labelProperty;
