import PhoneWidget from './phone.widget';
import { CustomWidgetCollection } from '@shared/surveyJs/reexport';

const Phone = (): void => {
  const widget = new PhoneWidget();
  CustomWidgetCollection.Instance.addCustomWidget(
    widget,
    widget.activatedBy
  );
};

export default Phone;
