import React, { FC, useEffect, useState } from 'react';
import { LocaleSelect } from '@shared/components';

import styles from './ExportModal.styl';

interface Props {
  text?: string;
  noteText?: string;
  selectText?: string;
  initialLocale: string;
  changeLocale: (locale: string) => void;
}

const ExportModal: FC<Props> = ({
  text,
  noteText,
  selectText,
  initialLocale,
  changeLocale
}) => {
  const [ locale, setLocale ] = useState<string>(initialLocale);

  useEffect(() => {
    changeLocale(locale);
  }, [ locale ]);

  const textElement = text
    ? <p className={ styles.text }>{ text }</p>
    : null;

  const noteElement = noteText
    ? <p className={ styles.note }>{ noteText }</p>
    : null;

  return (
    <div className={ styles.modal }>
      { textElement }
      { noteElement }
      <LocaleSelect
        selectText={ selectText }
        localeState={ [ locale, setLocale ] }
      />
    </div>
  );
};
export default ExportModal;
