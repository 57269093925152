import { Observable } from 'rxjs';
import { Api } from '@services/api';

const apiUrl: string = '/api/survey/auth';

class AuthenticationApiService extends Api {
  public logout(token: string): Observable<null> {
    const headers = { Authorization: `Bearer ${ token }` };
    return this.get<null>({
      url: 'logout',
      headers,
      suppressDefaultErrorHandler: true
    });
  }
}

export const authenticationApiService = new AuthenticationApiService(apiUrl);
