import { IProperty } from '../../interfaces';

const singleDateProperty: IProperty = {
  name: 'singleDate',
  category: 'calendar',
  type: 'switch',
  default: true,
  visible: true,
  visibleIndex: 0
};

export default singleDateProperty;
