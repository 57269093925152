export interface IPasswordGeneratorOptions {
  minLowerCaseCharacters: number;
  minUpperCaseCharacters: number;
  minSpecialCharacters: number;
  minNumericCharacters: number;
}

const alphabeticalCharacters: string = 'abcdefghijklmnopqrstuvwxyz';
const specialCharacters: string = '~!@#$%^&*()_+-*/=';
const numericCharacters: string = '0123456789';
const lowerCaseCharacterList: string[] = alphabeticalCharacters.split('');
const upperCaseCharacterList: string[] = lowerCaseCharacterList.map(
  (character: string) => character.toUpperCase()
);
const specialCharacterList: string[] = specialCharacters.split('');
const numericCharacterList: string[] = numericCharacters.split('');

const defaultOptions: IPasswordGeneratorOptions = {
  minLowerCaseCharacters: 1,
  minUpperCaseCharacters: 1,
  minSpecialCharacters: 1,
  minNumericCharacters: 1
};

const getRandomInteger = (min: number, max: number) =>
  Math.floor(min + Math.random() * (max + 1 - min));

const numberDictionarySum = (object: Record<string, number>): number =>
  (Object.keys(object) as Array<keyof object>).reduce(
    (sum: number, key: keyof object) => sum + object[key],
    0
  );

const getRandomArrayItem = <T>(array: T[]): T =>
  array[getRandomInteger(0, array.length - 1)];

export const generatePassword = (
  length: number = 12,
  options: IPasswordGeneratorOptions = defaultOptions
): string => {
  const {
    minLowerCaseCharacters: minLowerCase,
    minUpperCaseCharacters: minUpperCase,
    minSpecialCharacters: minSpecial,
    minNumericCharacters: minNumeric
  } = options;
  const randomCharactersLength =
    length - (minLowerCase + minUpperCase + minSpecial + minNumeric);
  if (randomCharactersLength < 0) {
    console.error('PasswordGenerator options is invalid!');
    return '';
  }
  const configuration: Record<string, number> = {
    lowerCase: minLowerCase,
    upperCase: minUpperCase,
    special: minSpecial,
    numeric: minNumeric
  };
  const configurationKeys = Object.keys(configuration) as Array<keyof object>;
  while (length > numberDictionarySum(configuration)) {
    const index: number = getRandomInteger(0, configurationKeys.length - 1);
    configuration[configurationKeys[index]]++;
  }
  const lowerCaseList = Array.from({ length: configuration.lowerCase }, () =>
    getRandomArrayItem(lowerCaseCharacterList)
  );
  const upperCaseList = Array.from({ length: configuration.upperCase }, () =>
    getRandomArrayItem(upperCaseCharacterList)
  );
  const specialList = Array.from({ length: configuration.special }, () =>
    getRandomArrayItem(specialCharacterList)
  );
  const numericList = Array.from({ length: configuration.numeric }, () =>
    getRandomArrayItem(numericCharacterList)
  );
  return [ ...lowerCaseList, ...upperCaseList, ...specialList, ...numericList ]
    .sort(() => 0.5 - Math.random())
    .join('');
};
