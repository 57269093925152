import { State } from 'router5';
import { defaultRoute, isExternalRoute, redirectToLobby } from '@router';
import { productService } from '@services';
import { redirectParameterName } from '../middleware';

export const defaultRouteMiddleware = () => (toState: State, fromState: State): Promise<void> => {
  if (toState.path === '/') {
    const fromName = fromState === null
      ? null
      : fromState.name;
    const product = productService.getCurrentProduct()!;
    const routeName = defaultRoute(product);
    if (isExternalRoute(routeName)) {
      redirectToLobby({ route: routeName, parameters: toState.params });
    }
    const redirect = {
      name: routeName,
      params: {
        [redirectParameterName]: true,
        openModal: toState.meta?.params.openModal
      },
      options: {
        reload: fromName === routeName
      }
    };
    return Promise.reject({ redirect });
  }
  return Promise.resolve();
};
