import { PageType } from '../interfaces';
import { publicRouteList } from '../configuration/publicRouteList';
import { bothRouteList } from '../configuration/bothRouteList';

const getPageType = (routeName: string): PageType => {
  if (bothRouteList.includes(routeName)) {
    return PageType.both;
  } else if (publicRouteList.includes(routeName)) {
    return PageType.public;
  }
  return PageType.private;
};

export default getPageType;
