import { IProperty } from '../../interfaces';

const apiUrlProperty: IProperty = {
  name: 'apiUrl',
  category: 'choices',
  type: 'string',
  default: '/api/survey/system/',
  isLocalizable: false,
  visible: true,
  visibleIndex: 0
};

export default apiUrlProperty;
