import React, { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { userService } from '@services';
import { AppLayout } from '@layout';
import { Footer, LanguageSwitcher } from '@layout/components';

import styles from './ErrorLayout.styl';

interface IOwnProps {
  children: ReactNode | ReactElement;
  disableMenu?: boolean;
}

type Props = IOwnProps;

const ErrorLayout: FC<Props> = ({ children, disableMenu }) => {
  const [ isLogged, setIsLogged ] = useState<null | boolean>(null);
  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    userService.isLoggedIn$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((isLoggedIn: boolean) => {
        setIsLogged(isLoggedIn);
      });
    return () => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  if (isLogged === null) {
    return null;
  }
  return isLogged
    ? (
      <AppLayout
        hideUser={ !isLogged }
        hideMenu={ !isLogged }
      >
        { children }
      </AppLayout>
    )
    : (
      <>
        <div className={ styles.bg }/>
        <div className={ styles.wrap }>
          <div className={ styles.language }>
            <div className={ styles.languageSwitcher }>
              <LanguageSwitcher/>
            </div>
          </div>
          <div className={ styles.children }>
            { children }
          </div>
          <Footer disableMenu={ disableMenu }/>
        </div>
      </>
    );
};

export default ErrorLayout;
