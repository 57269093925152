import { StylesManager } from '@shared/surveyJs/reexport';

const defaultThemeColorsSurvey = StylesManager.ThemeColors.default;
defaultThemeColorsSurvey['$body-background-'] = '#FFF';
defaultThemeColorsSurvey['$body-container-background-color'] = '#FFF';
defaultThemeColorsSurvey['$inputs-background-color'] = '#FFF';
defaultThemeColorsSurvey['$error-background-color'] = '#FFF';
defaultThemeColorsSurvey['$header-background-color'] = '#FFF';
defaultThemeColorsSurvey['$header-color'] = '#1B143F';
defaultThemeColorsSurvey['$main-color'] = '#1B143F';
defaultThemeColorsSurvey['$main-hover-color'] = '#2D5076';
defaultThemeColorsSurvey['$text-color'] = '#1B143F';
defaultThemeColorsSurvey['$border-color'] = '#DDD';
defaultThemeColorsSurvey['$error-color'] = '#DE283F';

StylesManager.applyTheme();
