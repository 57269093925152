import { IProperty } from '../../interfaces';
import { ICalendarQuestion } from '../calendar.widget';

const singleCalendarProperty: IProperty = {
  name: 'singleCalendar',
  dependsOn: 'singleDate',
  category: 'calendar',
  type: 'switch',
  default: false,
  visible: true,
  visibleIf: (question: ICalendarQuestion) => {
    return !question.singleDate;
  },
  visibleIndex: 1
};

export default singleCalendarProperty;
