import { FileUploadWidgetError, IFileUploadQuestion, IStateItem } from '../../widgets/FileUpload/fileUpload.typings';
import { megabytesToBytes } from '../../widgets/FileUpload/utilities';

export const validateState = (question: IFileUploadQuestion): FileUploadWidgetError[] => {
  const { isRequired, minFileCount, maxFileCount, maxSize } = question;
  const errorList: FileUploadWidgetError[] = [];

  if (isRequired && !question.fileCount) {
    errorList.push(FileUploadWidgetError.REQUIRED);
  }

  if (!!minFileCount && (isRequired || question.fileCount) && question.fileCount < +minFileCount) {
    errorList.push(FileUploadWidgetError.MIN);
  }

  if (!!maxFileCount && question.fileCount > +maxFileCount) {
    errorList.push(FileUploadWidgetError.MAX);
  }

  const maxUploadSize = maxSize ? megabytesToBytes(+maxSize) : 0;
  if (!!maxUploadSize && question.totalSize > maxUploadSize) {
    errorList.push(FileUploadWidgetError.OVERSIZE);
  }

  const fileErrorCount = question.stateItemList
    .reduce(
      (result: number, item: IStateItem): number => result + item.data.errorList.length
      , 0
    );
  if (!!fileErrorCount) {
    errorList.push(FileUploadWidgetError.HAS_ERRORS);
  }
  return errorList;
};
