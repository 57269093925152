import { IProperty } from '../../interfaces';

const showPlaceholderProperty: IProperty = {
  name: 'showPlaceholder',
  category: 'general',
  type: 'switch',
  default: true,
  visible: true,
  nextToProperty: 'optionsCaption',
  visibleIndex: 5
};

export default showPlaceholderProperty;
