import React, { FC, ReactElement } from 'react';
import { IAreaChartSettings, ICoordinates } from '../index';
import { getCoordinates, getPoint } from '../utilities';

import styles from '../AreaChart.styl';

interface IOwnProps {
  index: number;
  value: number;
  settings: IAreaChartSettings;
}

type Props = IOwnProps;

const Marker: FC<Props> = ({ index, value, settings }): ReactElement => {
  const coordinates: ICoordinates = getCoordinates(
    settings.parameters.baseAngle * index,
    settings.parameters.maxValueDictionary[index]
      ? value / settings.parameters.maxValueDictionary[index] * settings.parameters.axisLength
      : 0
  );

  return (
    <circle
      key={coordinates.x + coordinates.y}
      className={styles.marker}
      r={3}
      cx={getPoint(coordinates.x)}
      cy={getPoint(coordinates.y)}
    />
  );
};

export default Marker;
