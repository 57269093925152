import { IProperty } from '../../interfaces';

const allowAddNewTagProperty: IProperty = {
  name: 'allowAddNewTag',
  category: 'general',
  type: 'switch',
  default: false,
  visible: true,
  visibleIndex: 2
};

export default allowAddNewTagProperty;
