import React, { FC, ReactElement } from 'react';
import {
  faCheckCircle,
  faQuestionCircle,
  faExclamationCircle
} from '@fortawesome/pro-regular-svg-icons';
import { StatusColor, StatusIcon, StatusIconData } from '@shared/components';

export enum ComplianceStatusType {
  COMPLIANT = 'COMPLIANT',
  UNKNOWN = 'UNKNOWN',
  NON_COMPLIANT = 'NON_COMPLIANT'
}

interface IOwnProps {
  status: ComplianceStatusType;
  className?: string;
}
type Props = IOwnProps;

export const ComplianceStatus: FC<Props> = ({ status, className }): ReactElement => {
  const settings: StatusIconData = {
    COMPLIANT: {
      icon: faCheckCircle,
      titleTranslationKey: 'complianceStatus.COMPLIANT',
      color: StatusColor.success
    },
    UNKNOWN: {
      icon: faQuestionCircle,
      titleTranslationKey: 'complianceStatus.UNKNOWN',
      color: StatusColor.default
    },
    NON_COMPLIANT: {
      icon: faExclamationCircle,
      titleTranslationKey: 'complianceStatus.NON_COMPLIANT',
      color: StatusColor.danger
    }
  };

  return (
    <StatusIcon
      className={className}
      settings={settings}
      value={status}
      dataAttributesDictionary={{
        test: { 'compliance-status': status },
        guide: { 'compliance-status': status }
      }}
    />
  );
};
