import $ from 'jquery';

import 'select2/dist/js/select2.full.js';
import 'select2/dist/css/select2.css';

import {
  Adapter,
  Button,
  Country,
  ApiDropdown,
  Dictionary,
  Dropdown,
  Link,
  Phone,
  Calendar,
  FileUpload
} from '../../widgets';

import { renameSurveyClass } from '../../widgets/Dropdown/utilities';
renameSurveyClass('dropdown', 'dropdownbase');

Adapter();
Button();
Country($);
ApiDropdown($);
Dropdown($);
Dictionary($);
Link();
Phone();
Calendar();
FileUpload();
