import { IProperty } from '../../interfaces';

const dropdownAutoWidthProperty: IProperty = {
  name: 'dropdownAutoWidth',
  category: 'layout',
  type: 'switch',
  default: false,
  visible: true
};

export default dropdownAutoWidthProperty;
