import { Translation, TranslationArg } from 'select2';

const language = (locale: 'en' | 'de'): Translation => ({
  errorLoading: () =>
    locale === 'de'
      ? 'Fehler beim Laden der Daten'
      : 'Error loading data',
  inputTooLong: (args: TranslationArg) => {
    const overChars = args.input.length - args.maximum;
    if (locale === 'de') {
      return `Bitte "${ overChars } Zeichen weniger eingeben`;
    } else {
      let result = `Please delete ${ overChars } character`;
      if (1 !== overChars) {
        result += 's';
      }
      return result;
    }
  },
  inputTooShort: (args: TranslationArg) => {
    const remainingChars = args.minimum - args.input.length;
    return locale === 'de'
      ? `Bitte ${ remainingChars } Zeichen mehr eingeben`
      : `Please enter ${ remainingChars } or more characters`;
  },
  loadingMore: () =>
    locale === 'de' ? 'Lade weitere Daten…' : 'Loading more…',
  maximumSelected: (args: TranslationArg) => {
    if (locale === 'de') {
      let result = `Sie können nur ${ args.maximum } Element`;
      if (1 !== args.maximum) {
        result += 'e';
      }
      result += ' auswählen';
      return result;
    } else {
      let result = `You can only select ${ args.maximum } item`;
      if (1 !== args.maximum) {
        result += 's';
      }
      return result;
    }
  },
  noResults: () => {
    return locale === 'de'
      ? 'Keine Ergebnisse gefunden'
      : 'No results found';
  },
  searching: () => (locale === 'de' ? 'Suche…' : 'Searching…')
});

export default language;
