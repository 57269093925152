import { parseDataTransferItemList, parseFileList } from '../utilities';
import { IFileUploadQuestion } from '../fileUpload.typings';

export const parseDropEvent = (
  event: DragEvent,
  question: IFileUploadQuestion
): Promise<File[]> => {
  const { dataTransfer } = event;
  if (!dataTransfer) {
    return Promise.resolve([]);
  }
  if (dataTransfer.items) {
    const dataTransferItemList = dataTransfer.items;
    return parseDataTransferItemList(dataTransferItemList, question.ignoreHiddenFiles);
  } else if (dataTransfer.files) {
    return Promise.resolve(parseFileList(dataTransfer.files));
  }
  return Promise.resolve([]);
};
