import $ from 'jquery';
import { DataFormat, GroupedDataFormat, IdTextPair, LoadingData } from 'select2';
import { isLoadingData } from '@utilities';

import styles from './ManualFilter.styl';

export const templateSelection = (
  state: DataFormat | GroupedDataFormat | LoadingData | IdTextPair
): string | JQuery => {
  const $result = $(`<span class="${ styles.systemEvent }" />`);
  if (
    (isLoadingData(state) && state.loading)
    || state.id === ''
    || state.id === 'false'
  ) {
    return $result.text(state.text);
  }
  $result.attr('title', state.text);
  const text = state.text;
  $result.append(text);
  return $result;
};
