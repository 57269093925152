import { Options } from 'select2';
import templateResult from './templateResult';

const getDictionarySelect2Options = (
  select: HTMLSelectElement,
  isMultiple: boolean
): Options => {
  return {
    width: '100%',
    containerCssClass: 'dse-dictionary-dropdown-container',
    dropdownCssClass: 'dse-dictionary-dropdown-dropdown',
    templateResult: templateResult(select, isMultiple)
  };
};

export default getDictionarySelect2Options;
