export type ExternalRouteListType = 'login' | 'onboarding' | 'products' | 'profile' | 'organization' | 'organizationTab';
export type RouteListType = keyof typeof routeList;
export type FullRouteListType = ExternalRouteListType | RouteListType;

export enum routeList {
  notAllowed = '/error/403',
  notFound = '/error/404',
  documentError = '/error/:type<document>/:context<topic|id>/:id<[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}>',

  default = '/',

  dpoNomination = '/dpo-nomination',
  dpoPrivacyPolicy = '/dpo-privacy-policy/:id',
  dpoNotification = '/dpo-notification/:id',

  surveyTaskSystemAssignment = '/task/system-assignment/:id',
  customProcessesAdd = '/processes/custom/:functionalArea<hr|it|sales|purchase|finance|security|third-party-processing>',
  customProcesses = '/processes/custom/:functionalArea<hr|it|sales|purchase|finance|security|third-party-processing>/:id<[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}>',
  processSelection = '/process/selection/:functionalArea<hr|it|sales|purchase|finance|security|third-party-processing>',
  surveyTask = '/task/:type<action|additionalProcessSelection|auditConcept|process|processSelection>/:id',
  surveyTaskView = '/task/view/:type<action|additionalProcessSelection|auditConcept|process|processSelection>/:id',

  dashboard = '/dashboard',
  dashboardTab = '/dashboard/:routeTab<assessment>',

  analytics = '/analytics',
  analyticsTab = '/analytics/:routeTab<activities>',

  processes = '/processes?showOpenTasks',
  processesTab = '/processes/:routeTab<it|sales|purchase|finance|security|third-party-processing>?showOpenTasks',

  tom = '/tom?showOpenTasks',

  riskStatic = '/risk',
  risk = '/risk',
  riskTab = '/risk/:routeTab<closed>',
  riskAdd = '/risk/add',
  riskEdit = '/risk/:riskType<pre-assessment|pre-check|assessment-dpm|assessment-dpo|dpia-dpm|dpia-dpo|preliminary-consultation>/:id<[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$>',
  riskView = '/risk/view/:riskType<pre-assessment|pre-check|assessment-dpm|assessment-dpo|dpia-dpm|dpia-dpo|preliminary-consultation>/:id<[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$>',

  document = '/documents',
  documentTab = '/documents/:routeTab<shared|rpa|data-breach|risk|uploads>',

  websites = '/websites',
  dataSubjectRequests = '/data-subject-requests',
  dataBreachStaticDefault = '/data-breach',
  dataBreachStaticCustom = '/data-breach',
  dataBreach = '/data-breach',
  dataBreachTab = '/data-breach/:routeTab<archive>',
  dataBreachAdd = '/data-breach/add',
  dataBreachEdit = '/data-breach/:id<[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$>',
  dataBreachView = '/data-breach/view/:id<[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$>',

  team = '/data-protection-team',
  teamDetails = '/data-protection-team/:teamArea<third-party-processing|dpo|hr|it|sales|purchase|finance|security>',

  systems = '/systems',
  systemsList = '/systems/:url<business-premise|associated-company|website|freelancer|service-provider|principal|data-storage-self-hosted|data-storage-third-party-hosted|data-storage-cloud-hosted|software>',
  systemsAdd = '/systems/:url<business-premise|associated-company|website|freelancer|service-provider|principal|data-storage-self-hosted|data-storage-third-party-hosted|data-storage-cloud-hosted|software>/add',
  systemsDetails = '/systems/:url<business-premise|associated-company|website|freelancer|service-provider|principal|data-storage-self-hosted|data-storage-third-party-hosted|data-storage-cloud-hosted|software>/:id<[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$>',

  myCourses = '/my-courses',
  courseManagement = '/course-catalogue',

  assetManagement = '/asset-management',
  assetManagementTab = '/asset-management/:routeTab<third-parties|data-storages>'
}
