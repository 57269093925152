import { IProperty } from '../../interfaces';
import { QuestionCheckboxModel } from '@shared/surveyJs/reexport';

const otherTextProperty: IProperty = {
  name: 'otherText',
  category: 'choices',
  type: 'string',
  default: 'Other',
  isLocalizable: true,
  dependsOn: 'hasOther',
  visibleIf: (question: QuestionCheckboxModel) => question.hasOther,
  nextToProperty: 'hasOther'
};

export default otherTextProperty;
