import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { INewsItem, newsService } from '@services';

export const newsResolver = (): Observable<INewsItem[]> => newsService.getNewsCollection()
  .pipe(
    take(1),
    catchError(() => of(null)),
    map((newsList: null | INewsItem[]) => newsList === null
      ? []
      : newsList
    )
  );
