export const getCoordinateX = (angle: number, distance: number) => Math.cos(angle - Math.PI / 2) * distance;
export const getCoordinateY = (angle: number, distance: number) => Math.sin(angle - Math.PI / 2) * distance;
export const getCoordinates = (angle: number, distance: number) => ({
  x: getCoordinateX(angle, distance),
  y: getCoordinateY(angle, distance)
});
export const getBaseAngle = (axisCount: number) => Math.PI * 2  / axisCount;
export const classNames = (classes: Record<string, boolean>): string => Object
  .entries(classes)
  .filter(([ _key, value ]) => value)
  .map(([ key, _value ]) => key)
  .join(' ');

export const getPoint = (value: number): string => value.toFixed(4);
export const  arrayRotate = (array: any[], reverse: boolean = true) => {
  if (reverse) {
    array.unshift(array.pop());
  } else {
    array.push(array.shift());
  }
  return array;
};
