export const hasClass = (element: Element, className: string): boolean => element.classList
  ? element.classList.contains(className)
  : !!element.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));

const getClassList = (className: string | string[]): string[] => {
  if (!className) {
    return [];
  }
  const classNameList = Array.isArray(className) ? className : [ className ];
  return classNameList.filter(Boolean);
};
export const addClass = (element: Element, className: string | string[]): void => {
  const classList = getClassList(className);
  if (classList.length) {
    element.classList.add(...classList);
  }
};

export const removeClass = (element: Element, className: string | string[]): void => {
  const classList = getClassList(className);
  if (classList.length) {
    element.classList.remove(...classList);
  }
};

export const toggleClass = (element: Element, className: string): void => hasClass(element, className)
  ? addClass(element, className)
  : removeClass(element, className);

export const setClass = (element: Element, className: string, value: boolean = false) => value
  ? addClass(element, className)
  : removeClass(element, className);
