import { Observable } from 'rxjs';
import { TaskTypeType } from '@interfaces';
import {
  ISurveyData,
  ISurveyParams,
  ISurveyRequestData,
  ISurveyResult,
  RiskType,
  RPAFileType,
  SystemsType
} from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/survey';

class SurveyApiService extends Api {
  public getSurveyData(
    id: string,
    taskType: TaskTypeType,
    parameters: Partial<ISurveyParams>,
    suppressDefaultErrorHandler?: boolean | number[]
  ): Observable<ISurveyData> {
    return this.get<ISurveyData>({
      url: `${ taskType }/${ id }`,
      parameters,
      suppressDefaultErrorHandler
    });
  }

  public getSurveyByName(name: string): Observable<ISurveyData> {
    return this.get<ISurveyData>({ url: name });
  }

  public getSurveyByFunctionalArea(params: Record<string, string> = {}): Observable<ISurveyData> {
    return this.get<ISurveyData>({
      url: 'process-selection',
      parameters: { params }
    });
  }

  public getCustomProcess(params: Record<string, string> = {}): Observable<ISurveyData> {
    return this.get<ISurveyData>({
      url: 'custom-process',
      parameters: { params }
    });
  }

  public getSurveyByProcessId(processId: number, parameters: Record<string, string> = {}): Observable<ISurveyData> {
    return this.get<ISurveyData>({
      url: `process-detail/${ processId }`,
      parameters
    });
  }

  public getSurveyByAuditConceptId(auditId: number, parameters: Record<string, string> = {}): Observable<ISurveyData> {
    return this.get<ISurveyData>({
      url: `audit-concept/${ auditId }`,
      parameters
    });
  }

  public getSurveyBySystemsType(type: SystemsType, parameters: Record<string, string> = {}): Observable<ISurveyData> {
    return this.get<ISurveyData>({
      url: `basis-data/${ type }`,
      parameters
    });
  }

  public getWebsiteAuditSurvey(): Observable<ISurveyData> {
    return this.get<ISurveyData>({ url: 'statement/websiteAudit' });
  }

  public createRPADocument(fileType: RPAFileType, locale: string): Observable<any> {
    return this.post({
      url: 'company-data/rpa-document',
      parameters: { fileType, locale }
    });
  }

  public saveSurveyResult<T>(
    id: string,
    parameters: Record<string, string>,
    body: ISurveyRequestData<T>
  ): Observable<ISurveyResult> {
    return this.post({
      url: `result/${ id }`,
      parameters,
      body
    });
  }

  public updateSurveyResult<T>(
    id: string,
    parameters: Record<string, string>,
    body: ISurveyRequestData<T>
  ): Observable<ISurveyResult> {
    return this.put({
      url: `result/${ id }`,
      parameters,
      body
    });
  }

  public getDataBreach(params: Record<string, boolean | string> = {}): Observable<ISurveyData> {
    return this.get<ISurveyData>({
      url: 'data-breach-survey',
      parameters: { params }
    });
  }

  public getRisk(type: RiskType, params: Record<string, boolean | string> = {}): Observable<ISurveyData> {
    return this.get<ISurveyData>({
      url: `risk/${ type }`,
      parameters: { params }
    });
  }
}
export const surveyApiService = new SurveyApiService(apiUrl);
