import $ from 'jquery';
import { DataFormat, GroupedDataFormat, IdTextPair, LoadingData } from 'select2';
type SelectionType = DataFormat | GroupedDataFormat | LoadingData | IdTextPair;

export const templateSelection = (state: SelectionType): string | JQuery => {
  const $result = $('<span />');
  if (!!state.id && state.id !== 'false') {
    const countryCode = (state.id as string).toLowerCase();
    $result.addClass(`country-flag flag-icon flag-icon-${ countryCode }`);
    $result.attr('title', state.text);
  }
  return $result;
};
