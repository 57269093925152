import $ from 'jquery';
import { DataFormat, GroupedDataFormat, LoadingData } from 'select2';
import { isLoadingData, markMatch } from '@utilities';

type ItemType = DataFormat | GroupedDataFormat | LoadingData;

export const templateResult = ($select: JQuery<HTMLSelectElement>) => (item: ItemType): string | JQuery | null => {
  const $result = $('<span />');
  if ((isLoadingData(item) && item.loading) || item.id === 'false') {
    return $result.text(item.text);
  }
  const term = $select.data('select2').dropdown.$search.val() || '';
  const $text = markMatch(item.text, term);
  const countryCode = (item.id as string).toLowerCase();
  const $flag = $(
    `<span class="country-flag flag-icon flag-icon-${ countryCode }" />`
  );
  $result.append($flag);
  $result.append($text);
  return $result;
};
