import { FileError, IFileUploadQuestion, IStateItem, Status } from '../../widgets/FileUpload/fileUpload.typings';
import { acceptedTypesStringToArray, megabytesToBytes } from '../../widgets/FileUpload/utilities';

export const validateStateItemList = (question: IFileUploadQuestion): void => {
  const { acceptedTypes, maxSize, maxFileSize } = question;
  const acceptedTypeList: string[] = acceptedTypesStringToArray(acceptedTypes);
  const maxSizeValue = megabytesToBytes(maxFileSize && +maxFileSize || maxSize && +maxSize || 0);
  const fileNameList = question.stateItemList.map((item: IStateItem) => `${ item.data.name }.${ item.data.extension }`);
  question.stateItemList.forEach((item: IStateItem) => {
    const errorList: FileError[] = item.data.errorList.includes(FileError.UPLOAD)
      ? [ FileError.UPLOAD ]
      : [];
    const { size, name, extension } = item.data;

    if (acceptedTypeList.length && !acceptedTypeList.includes(extension.toLowerCase())) {
      errorList.push(FileError.TYPE);
    }

    if (maxSizeValue && size > maxSizeValue) {
      errorList.push(FileError.OVERSIZE);
    }

    const occurrences = fileNameList
      .reduce(
        (result: number, fileName: string): number => (fileName === `${ name }.${ extension }`)
          ? result + 1
          : result,
        0
      );
    if (occurrences !== 1) {
      errorList.push(FileError.DUPLICATE);
    }

    item.data.errorList = errorList;
    item.data.status = errorList.length
      ? Status.ERROR
      : item.data.status === Status.ERROR
        ? item.value === null
          ? Status.WAITING
          : item.attachment === null
            ? Status.PRELOADED
            : Status.UPLOADED
        : item.data.status;
  });
};
