import { IProperty } from '../../interfaces';

const hasOtherProperty: IProperty = {
  name: 'hasOther',
  category: 'choices',
  type: 'switch',
  default: false,
  visible: true,
  nextToProperty: 'allowClear'
};

export default hasOtherProperty;
