import { useRoute } from 'react-router5';

export const useClearLeavePageHandler = (isReadonly: boolean = false) => {
  const { router } = useRoute();
  const routeName = router.getState().name;

  const clearLeavePageHandler = (): void => {
    if (!isReadonly) {
      router.clearCanDeactivate(routeName);
    }
  };

  return { clearLeavePageHandler };
};
