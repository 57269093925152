import { Observable } from 'rxjs';
import { TaskTypeDecode } from '@interfaces';
import {
  COMPLETED,
  ISurveyService,
  RUNNING,
  ISurveyData,
  ISurveyParams,
  ISurveyResult,
  ISurveyRequestData
} from '@services';
import { surveyApiService } from '@services/api';

const surveyData = <T>(pageNo: number, status: COMPLETED | RUNNING, data: T): ISurveyRequestData<T> => {
  return { lastActivePage: pageNo, status, result: data };
};

const surveyPostDecodeParams = (params: Record<string, string>): Record<string, string> => {
  const result = { ...params };
  if (result.type && TaskTypeDecode[result.type as keyof typeof TaskTypeDecode]) {
    result.type = TaskTypeDecode[result.type as keyof typeof TaskTypeDecode];
  }
  return result;
};

const getSurveyData = (params: ISurveyParams, suppressDefaultErrorHandler?: boolean | number[]): Observable<ISurveyData> => {
  const { id, type } = params;
  const taskType = TaskTypeDecode[type];
  const parameters: Partial<ISurveyParams> = {};
  if (params.showLastResult) {
    parameters.showLastResult = params.showLastResult;
  }
  if (params.forceReadonly) {
    parameters.forceReadonly = params.forceReadonly;
  }
  return surveyApiService.getSurveyData(id, taskType, parameters, suppressDefaultErrorHandler);
};

const saveSurveyResult = <T>(
  id: string,
  pageNo: number,
  status: COMPLETED | RUNNING,
  data: T,
  parameters: Record<string, string>
): Observable<ISurveyResult> => surveyApiService
  .updateSurveyResult(id, surveyPostDecodeParams(parameters), surveyData(pageNo, status, data));

const updateSurveyResult = <T>(
  id: string,
  pageNo: number,
  status: COMPLETED | RUNNING,
  data: T,
  parameters: Record<string, string>
): Observable<ISurveyResult> => surveyApiService
  .updateSurveyResult(id, surveyPostDecodeParams(parameters), surveyData(pageNo, status, data));

export const surveyService: ISurveyService = {
  getSurveyData,
  getSurveyByName: surveyApiService.getSurveyByName.bind(surveyApiService),
  getSurveyByFunctionalArea: surveyApiService.getSurveyByFunctionalArea.bind(surveyApiService),
  getCustomProcess: surveyApiService.getCustomProcess.bind(surveyApiService),
  getSurveyByProcessId: surveyApiService.getSurveyByProcessId.bind(surveyApiService),
  getSurveyByAuditConceptId: surveyApiService.getSurveyByAuditConceptId.bind(surveyApiService),
  getSurveyBySystemsType: surveyApiService.getSurveyBySystemsType.bind(surveyApiService),
  createRPADocument: surveyApiService.createRPADocument.bind(surveyApiService),
  saveSurveyResult,
  updateSurveyResult
};
