import { Observable } from 'rxjs';
import { Api } from '@services/api';

const apiUrl = '/api/academy/company-course';

class InvitationApiService extends Api {
  public generateInvitationLink(companyCourseId: number): Observable<string> {
    return this.post<string>({
      url: `${ companyCourseId }/invitation-link`,
      responseType: 'text'
    });
  }

  public deactivateInvitationLink(companyCourseId: number): Observable<void> {
    return this.delete({ url: `${ companyCourseId }/invitation-link` });
  }
}

export const invitationApiService = new InvitationApiService(apiUrl);
