import { IProperty } from '../../interfaces';
import { QuestionCheckboxModel } from '@shared/surveyJs/reexport';

const selectAllTextProperty: IProperty = {
  name: 'selectAllText',
  category: 'choices',
  type: 'string',
  default: 'Select all',
  isLocalizable: true,
  dependsOn: 'hasSelectAll',
  visibleIf: (question: QuestionCheckboxModel) => question.hasSelectAll,
  nextToProperty: 'hasSelectAll',
  visibleIndex: 21
};

export default selectAllTextProperty;
