import { IProperty } from '../../interfaces';

const maxSelectedChoicesProperty: IProperty = {
  name: 'maxSelectedChoices',
  category: 'choices',
  type: 'number',
  default: 0,
  isLocalizable: false,
  visible: true
};

export default maxSelectedChoicesProperty;
