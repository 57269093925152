import CalendarWidget from './calendar.widget';
import { CustomWidgetCollection } from '@shared/surveyJs/reexport';

const Calendar = (): void => {
  const widget = new CalendarWidget();
  CustomWidgetCollection.Instance.addCustomWidget(
    widget,
    widget.activatedBy
  );
};

export default Calendar;
