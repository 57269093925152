import { IFileUploadQuestion } from '@shared/surveyJs/widgets/FileUpload/fileUpload.typings';

export const getConvertedSize = (
  value: number,
  question: IFileUploadQuestion,
  getLocalization: (question: IFileUploadQuestion, key: string) => string,
  delimiter = ' ',
  decimals = 2
): string => {
  const sizeKeyList = [ 'b', 'Kb', 'Mb', 'Gb', 'Tb' ];
  if (value === 0) {
    return `0${ delimiter }${ getLocalization(question, sizeKeyList[0]) }`;
  }

  const size = 1024;
  const decimalsLength = decimals < 0 ? 0 : decimals;

  const index = Math.floor(Math.log(value) / Math.log(size));
  const result = parseFloat((value / Math.pow(size, index)).toFixed(decimalsLength));

  return `${ result }${ delimiter }${ getLocalization(question, sizeKeyList[index]) }`;
};
