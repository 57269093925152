type Icon = 'attachment' | 'exclamation' | 'sync' | 'trash' | 'fileSearch';
type IconDictionary = {
  [key in Icon]: {
    viewBox: string;
    d: string;
  }
};
const iconDictionary: IconDictionary = {
  exclamation: {
    viewBox: '0 0 512 512',
    d: 'M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z'
  },
  attachment: {
    viewBox: '0 0 512 512',
    d: 'M149.106 512c-33.076 0-66.153-12.59-91.333-37.771-50.364-50.361-50.364-132.305-.002-182.665L319.842 29.498c39.331-39.331 103.328-39.331 142.66 0 39.331 39.332 39.331 103.327 0 142.657l-222.63 222.626c-28.297 28.301-74.347 28.303-102.65 0-28.3-28.301-28.3-74.349 0-102.649l170.301-170.298c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971l-170.3 170.297c-15.821 15.821-15.821 41.563.001 57.385 15.821 15.82 41.564 15.82 57.385 0l222.63-222.626c26.851-26.851 26.851-70.541 0-97.394-26.855-26.851-70.544-26.849-97.395 0L80.404 314.196c-37.882 37.882-37.882 99.519 0 137.401 37.884 37.881 99.523 37.882 137.404.001l217.743-217.739c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971L240.44 474.229C215.26 499.41 182.183 512 149.106 512z'
  },
  sync: {
    viewBox: '0 0 512 512',
    d: 'M492 8h-10c-6.627 0-12 5.373-12 12v110.627C426.929 57.261 347.224 8 256 8 123.228 8 14.824 112.338 8.31 243.493 7.971 250.311 13.475 256 20.301 256h10.016c6.353 0 11.646-4.949 11.977-11.293C48.157 132.216 141.097 42 256 42c82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12zm-.301 248h-10.015c-6.352 0-11.647 4.949-11.977 11.293C463.841 380.158 370.546 470 256 470c-82.608 0-154.672-46.952-190.299-116H180c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H20c-6.627 0-12 5.373-12 12v160c0 6.627 5.373 12 12 12h10c6.627 0 12-5.373 12-12V381.373C85.071 454.739 164.777 504 256 504c132.773 0 241.176-104.338 247.69-235.493.339-6.818-5.165-12.507-11.991-12.507z'
  },
  trash: {
    viewBox: '0 0 448 512',
    d: 'M440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h18.9l33.2 372.3a48 48 0 0 0 47.8 43.7h232.2a48 48 0 0 0 47.8-43.7L421.1 96H440a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zm184.8 427a15.91 15.91 0 0 1-15.9 14.6H107.9A15.91 15.91 0 0 1 92 465.4L59 96h330z'
  },
  fileSearch: {
    viewBox: '0 0 640 512',
    d: 'M605.65 487.05l-88.89-88.88c16.86-21.68 27.31-48.58 27.31-78.17a128 128 0 1 0-128 128c29.55 0 56.43-10.42 78.1-27.24l88.9 88.89a8 8 0 0 0 11.32 0l11.31-11.31a8 8 0 0 0-.05-11.29zM416.05 416a96 96 0 1 1 96-96 96.12 96.12 0 0 1-96 96zM368 480H80a16 16 0 0 1-16-16V48.09a16 16 0 0 1 16-16h176v104a23.93 23.93 0 0 0 24 24l136-.09v-28a48.23 48.23 0 0 0-14.1-34L318 14.1A48 48 0 0 0 284.08 0H80a48.16 48.16 0 0 0-48 48.09V464a48 48 0 0 0 48 48h288a47.87 47.87 0 0 0 45.15-32.29 159.9 159.9 0 0 1-33.92-4.36A15.91 15.91 0 0 1 368 480zM288 32.59a15.73 15.73 0 0 1 7.4 4.2l83.9 83.89a15.75 15.75 0 0 1 4.2 7.39H288z'
  }
};
export const useIcon = (element: HTMLElement, iconName: Icon) => {
  const icon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const path = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path'
  );
  icon.setAttribute('viewBox', iconDictionary[iconName].viewBox);
  path.setAttribute('d', iconDictionary[iconName].d);
  icon.appendChild(path);
  return element.appendChild(icon);
};
