import React, { FC } from 'react';
import { IBasicScoreData } from '@services';

import styles from './ProgressBar.styl';

interface IOwnProps {
  data?: IBasicScoreData;
}

type Props = IOwnProps;

const defaultData: IBasicScoreData = {
  current: 0,
  total: 0
};

export const ProgressBar: FC<Props> = ({ data = defaultData }) => {
  const percent = !data.total ? 0 : data.current / data.total * 100;
  const style = {
    width: `${ percent }%`
  };
  return (
    <div className={styles.progressBar}>
      <div className={styles.progress} style={style} />
    </div>
  );
};
