import { IProperty } from '../../interfaces';

const minDateProperty: IProperty = {
  name: 'minDate',
  category: 'calendar',
  type: 'date',
  isLocalizable: true,
  visible: true,
  visibleIndex: 5
};

export default minDateProperty;
