import { IProperty } from '../../interfaces';

const showTimeProperty: IProperty = {
  name: 'showTime',
  category: 'calendar',
  type: 'switch',
  default: false,
  visible: true,
  visibleIndex: 4
};

export default showTimeProperty;
