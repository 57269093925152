import { permissionService } from '@services';
import { getRouterDataForPagePermission } from '@router';

const checkPathPermission = (path: string): boolean => {
  const permission = permissionService.pagePermissionSubject$.value as Record<string, string>;
  if (!permission) {
    return false;
  }
  const routeDataForPagePermission = getRouterDataForPagePermission(path);
  if (!routeDataForPagePermission) {
    return false;
  }
  if (routeDataForPagePermission.name === 'default') {
    return true;
  }
  return !!permission[routeDataForPagePermission.name];
};

export default checkPathPermission;
