import { IProperty } from '../../interfaces';

const maxRelativeDateProperty: IProperty = {
  name: 'maxRelativeDate',
  category: 'calendar',
  type: 'string',
  default: '',
  isLocalizable: false,
  visible: true,
  visibleIndex: 8
};

export default maxRelativeDateProperty;
