import { IProperty } from '../../interfaces';

const select2ConfigProperty: IProperty = {
  name: 'select2Config',
  category: 'data',
  type: 'string',
  default: '',
  visible: true,
  visibleIndex: 3
};

export default select2ConfigProperty;
