import { IProperty } from '../../interfaces';

const hasNoneProperty: IProperty = {
  name: 'hasNone',
  category: 'choices',
  type: 'switch',
  default: false,
  visible: true,
  visibleIndex: 15
};

export default hasNoneProperty;
