import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ComplianceScoreData } from '@services';
import { defaultConfig, undeterminedConfig } from '../ComplianceScoreWidget/config';

import styles from './ComplianceScoreWidget.styl';

export interface IComplianceConfig {
  percent: number;
  color: string;
  textColor: string;
  status: string;
}

export interface ICoordinates {
  x: number;
  y: number;
}

interface IOwnProps {
  value?: ComplianceScoreData;
  size?: number;
  spacing?: number;
  strokeWidth?: number;
  activeStrokeWidth?: number;
  spacingPercent?: number;
  totalDegree?: number;
  config?: IComplianceConfig[];
}

type Props = IOwnProps;

const ComplianceScoreWidget: FC<Props> = (
  {
    value = null,
    size = 300,
    strokeWidth = 0.07,
    activeStrokeWidth = 0.12,
    spacingPercent = 3.8,
    totalDegree = 180,
    config = defaultConfig
  }
) => {
  const { t } = useTranslation('dashboard');

  let cumulativePercent = 0;
  let activeSegment: null | IComplianceConfig = null;

  const arcDegree = totalDegree / 360;

  const getPercentCoordinates = (percent: number): ICoordinates => {
    const arcPercent = (percent / 100) * arcDegree;
    const x = Math.cos(2 * Math.PI * arcPercent);
    const y = Math.sin(2 * Math.PI * arcPercent);
    return { x, y };
  };

  const startAngle = (360 - totalDegree) / 2;
  const rotate = 90 + startAngle;
  const segments = config.map(
    (segment: IComplianceConfig, index: number): ReactElement => {
      let start = false;
      if (value !== null) {
        start = 100 * value > cumulativePercent || value === 0 && index === 0;
      }
      const startCoordinates = getPercentCoordinates(cumulativePercent + spacingPercent / 2);
      cumulativePercent += segment.percent;
      let end = false;
      if (value !== null) {
        end = 100 * value <= cumulativePercent;
      }
      const endCoordinates = getPercentCoordinates(cumulativePercent - spacingPercent / 2);
      const largeArcFlag = segment.percent > 0.5 ? 0 : 0;
      if (start && end) {
        activeSegment = segment;
      }
      const classes = start && end ? `${ styles.segment } ${ styles.active }` : styles.segment;
      const width = start && end ? activeStrokeWidth : strokeWidth;
      const color = value === null ? undeterminedConfig.color : segment.color;
      return (
        <path
          key={segment.status}
          className={classes}
          strokeLinecap="round"
          stroke={color}
          fill="rgba(0, 0, 0, 0)"
          strokeWidth={width}
          d={`M ${ startCoordinates.x } ${ startCoordinates.y } A 1 1 0 ${ largeArcFlag } 1 ${ endCoordinates.x } ${ endCoordinates.y }`}
        />
      );
    }
  );

  const scoreboard = (
    <g transform="translate(1 1)">
      <g
        viewBox="-1 -1 2 2"
        transform={`rotate(${ rotate })`}
      >
        {segments}
      </g>
    </g>
  );

  const arrow = () => {
    if (value === null) {
      return null;
    }
    const arrowAngle = startAngle - 180 + (100 * value * totalDegree) / 100;
    return (
      <polygon
        points="0,0 5,-7 10,0"
        stroke="#000000"
        className={styles.arrow}
        transform={`rotate(${ arrowAngle }, 1, 1) translate(0.93, 0.17) scale(0.012)`}
      />
    );
  };

  const text = () => {
    const segment = activeSegment === null ? undeterminedConfig : activeSegment;
    const classname = activeSegment === null ? `${ styles.scoreResult } ${ styles.undetermined }` : styles.scoreResult;
    return (
      <>
        <text textAnchor="middle" x="1" y="0.7" className={styles.status}>
          {t('complianceScoreWidget.status')}
        </text>
        <text textAnchor="middle" x="1" y="0.95" className={classname} fill={segment.textColor}>
          {t(`complianceScoreWidget.${ segment.status }`)}
        </text>
      </>
    );
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={styles.circle}
      width={size}
      height={size * 0.5}
      viewBox={`0 -${ strokeWidth } 2 ${ 2 * strokeWidth + 1.1 }`}
    >
      {scoreboard}
      {arrow()}
      {text()}
    </svg>
  );
};

export default ComplianceScoreWidget;
