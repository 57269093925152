import { IProperty } from '../../interfaces';

const minimumResultsForSearchProperty: IProperty = {
  name: 'minimumResultsForSearch',
  type: 'number',
  default: 5,
  category: 'choices',
  visibleIndex: 4,
  visible: true
};

export default minimumResultsForSearchProperty;
